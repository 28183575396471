import {customElement} from "solid-element";
import {Show} from "solid-js";

customElement("builderall-loader", {loading: false, blur: true, text: null}, props => {
    import("@lottiefiles/lottie-player");

    return (
        <>
            <style>{require("bundle-text:./styles/index.scss")}</style>
            <div class="loader">
                <slot/>
                <Show when={props.loading}>
                    <div classList={{'loader__overlay': true, 'show__blur': props.blur}}>
                        {/* @ts-ignore */}
                        <lottie-player class="loader__player" autoplay loop mode="normal"
                                       src={require("bundle-text:./assets/animation.lottie")}/>
                        <Show when={props.text}>
                            <span innerHTML={props.text}></span>
                        </Show>
                    </div>
                </Show>
            </div>
        </>
    );
});
