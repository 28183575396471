import { customElement } from "solid-element"
import { setTranslations, translations } from "../../services/context"
import { getTranslations } from "../../services/translation"
import { For, onMount, Show, createSignal, onCleanup, createEffect } from "solid-js"
import { sidebar, welcomeToolkit, breadcrumb, filesNav, filesFilters, previewSVGs, filesActions, checkbox, uploadPopup, anotherSVGs, alerts } from './assets/assets'
import { sendRequest } from "../../services/office-api"
import { sendGFRequest } from "../../services/global-file-api"
import welcomeImage from "./assets/banner.jpeg"

// Language
(async () => {
  if (!translations['toolkit-home']) {
    let userData = JSON.parse(localStorage.getItem("builderall-appshell--resources"))
    var userLang = userData?.user?.lang?.split("_")[0]?.toLowerCase() || 'us'
    setTranslations(await getTranslations(userLang))
    
    setFileTypes([
      { slug: 'all',   title: translations['toolkit-show-all'] },
      { slug: 'image', title: translations['toolkit-image'] },
      { slug: 'audio', title: translations['toolkit-audio'] },
      { slug: 'video', title: translations['toolkit-video'] },
      { slug: 'gif',   title: translations['toolkit-gif'] },
      { slug: 'other', title: translations['toolkit-others'] }
    ])

    setRoutes([
      { slug: 'home',   title: translations['toolkit-home'] },
      { slug: 'files',  title: translations['toolkit-my-files'] },
      { slug: 'stock',  title: translations['toolkit-stock-elements'] },
      { slug: 'brands', title: translations['toolkit-my-brands'] }
    ])

    setFilterOrderBy([
      { slug: 'asc',      title: translations['toolkit-ascending'],     type: 'asc',  column: ''},
      { slug: 'desc',     title: translations['toolkit-descending'],    type: 'desc', column: ''},
      { slug: 'smallest', title: translations['toolkit-size-smallest'], type: 'asc',  column: 'size'},
      { slug: 'largest',  title: translations['toolkit-size-largest'],  type: 'desc', column: 'size'},
      { slug: 'az',       title: 'A-z', type: 'asc',  column: 'name'},
      { slug: 'za',       title: 'Z-a', type: 'desc', column: 'name'},
    ])
  }
})()

const [step, setStep] = createSignal('home')
const [loading, setLoading] = createSignal(false)
const [uploadLoading, setUploadLoading] = createSignal(false)
const [myFolders, setMyFolders] = createSignal(null)
const [stockFolders, setStockFolders] = createSignal(null)
const [myFiles, setMyFiles] = createSignal(null)
const [brandFiles, setBrandFiles] = createSignal(null)
const [stockFiles, setStockFiles] = createSignal(null)
const [brandsElements, setBrandsElements] = createSignal(null)
const [myDeletedFiles, setMyDeletedFiles] = createSignal(null)
const [deletedBrandFiles, setDeletedBrandFiles] = createSignal(null)
const [deletedBrands, setDeletedBrands] = createSignal(null)
const [pagination, setPagination] = createSignal(null)
const [searchData, setSearchData] = createSignal(null)
const [newFolderName, setNewFolderName] = createSignal(null)
const [newFileName, setNewFileName] = createSignal(null)
const [searchTimer, setSearchTimer] = createSignal(null)
const [selectedFolderToUploadFile, setSelectedFolderToUploadFile] = createSignal(null)
const [selectedFolderToMoveFile, setSelectedFolderToMoveFile] = createSignal(null)
const [selectedBrandToMoveFile, setSelectedBrandToMoveFile] = createSignal(null)
const [selectedBrandToMerge, setSelectedBrandToMerge] = createSignal(null)
const [currentFolder, setCurrentFolder] = createSignal(null)
const [newUploadFile, setNewUploadFile] = createSignal([])
const [selectAllFiles, setSelectAllFiles] = createSignal(false)
const [showUploadFilePopup, setShowUploadFilePopup] = createSignal(false)
const [showDeleteFilePopup, setShowDeleteFilePopup] = createSignal(false)
const [showUnlinkFileBrandPopup, setShowUnlinkFileBrandPopup] = createSignal(false)
const [showDeleteFolderPopup, setShowDeleteFolderPopup] = createSignal(false)
const [showNewFolderPopup, setShowNewFolderPopup] = createSignal(false)
const [showNewBrandPopup, setShowNewBrandPopup] = createSignal(false)
const [showRenameFilePopup, setShowRenameFilePopup] = createSignal(false)
const [showMoveFilePopup, setShowMoveFilePopup] = createSignal(false)
const [showMoveFileToBrandPopup, setShowMoveFileToBrandPopup] = createSignal(false)
const [showMoveBrandFilePopup, setShowMoveBrandFilePopup] = createSignal(false)
const [showDeletedFolder, setShowDeletedFolder] = createSignal(false)
const [showUpdateBrandPopup, setShowUpdateBrandPopup] = createSignal(false)
const [showDeletedBrandPopup, setShowDeletedBrandPopup] = createSignal(false)
const [showSelectImagesToBrandPopup, setShowSelectImagesToBrandPopup] = createSignal(false)
const [showMergeBrandPopup, setShowMergeBrandPopup] = createSignal(false)
const [currentBrand, setCurrentBrand] = createSignal(null)
const [brandToDeleteAndMerge, setBrandToDeleteAndMerge] = createSignal(null)
const [selectedImageToBrand, setSelectedImageToBrand] = createSignal(null)
const [selectedFiles, setSelectedFiles] = createSignal([])
const [typeFileSelected, setTypeFileSelected] = createSignal('all')
const [filterAscDesc, setFilterAscDesc] = createSignal('desc')
const [filterGridList, setFilterGridList] = createSignal('grid')
const [percentCompleted, setPercentCompleted] = createSignal(0)
const [newBrandLogo, setNewBrandLogo] = createSignal([])
const [newBrandData, setNewBrandData] = createSignal({
  id: null,
  name: null,
  thumbnail: null,
  url: null
})
const [quotas, setQuotas] = createSignal({
  in_use: 0,
  released: 15
})
const [showToast, setShowToast] = createSignal(false)
const [toastData, setToastData] = createSignal({
  type: 'ba-success',
  message: translations['toolkit-success-upload'] || 'Success'
})
const [fileTypes, setFileTypes] = createSignal([
  { slug: 'all',   title: translations['toolkit-show-all'] || 'Show All' },
  { slug: 'image', title: translations['toolkit-image']    || 'Image' },
  { slug: 'audio', title: translations['toolkit-audio']    || 'Audio' },
  { slug: 'video', title: translations['toolkit-video']    || 'Video' },
  { slug: 'gif',   title: translations['toolkit-gif']      || 'Gif' },
  { slug: 'other', title: translations['toolkit-others']   || 'Others' }
])

const [routes, setRoutes] = createSignal([
  { slug: 'home',   title: translations['toolkit-home']           || 'Home' },
  { slug: 'files',  title: translations['toolkit-my-files']       || 'My Files' },
  { slug: 'stock',  title: translations['toolkit-stock-elements'] || 'Stock Elements' },
  { slug: 'brands', title: translations['toolkit-my-brands']      || 'My Brands' }
])

const [filterOrderBy, setFilterOrderBy] = createSignal([
  { slug: 'asc',      title: translations['toolkit-ascending']     || 'Ascending',       type: 'asc',  column: ''},
  { slug: 'desc',     title: translations['toolkit-descending']    || 'Descending',      type: 'desc', column: ''},
  { slug: 'smallest', title: translations['toolkit-size-smallest'] || 'Size (smallest)', type: 'asc',  column: 'size'},
  { slug: 'largest',  title: translations['toolkit-size-largest']  || 'Size (largest)',  type: 'desc', column: 'size'},
  { slug: 'az',       title: 'A-z', type: 'asc',  column: 'name'},
  { slug: 'za',       title: 'Z-a', type: 'desc', column: 'name'},
])

customElement("builderall-digital-file-repository", { tool: "", token: "", show: false, step: 'home', inputid: null, accept: null, showeditoption: false, hideapplybutton: false }, props => {
  
  createEffect(() => {
    changeRoute(props.step)
  })

  onMount(async () => {
    setLoading(true)
    var [quotas] = await Promise.all([
      getQuotas()
    ])

    setQuotas(quotas.storage)
    changeRoute(props.step)
    setLoading(false)
  })

  // Methods
  const changeFilterAscDesc = (data) => {
    setFilterAscDesc(data)
    if(step() == 'files') {
      getFiles()
    } else if (step() == 'stock') {
      getStockFiles()
    } else if (step() == 'brands') {
      if(!isEmpty(currentBrand())){
        getBrandFiles()
      } else {
        getBrandsElements()
      }
    }
  }

  const changeCurrentFolder = (data, type: string = null) => {
    if(step() == 'files') {
      setShowDeletedFolder(false)
      setCurrentFolder(data)
      getFiles()
    } else if (step() == 'stock') {
      if (type != 'secondary') {
        setStockFolders((prev) => 
          prev.map((folder) => ({ ...folder, show_secondary: false }))
        )
      }
      setCurrentFolder(data)
      getStockFiles()
    }
  }

  const changeTypeFile = (data) => {
    setTypeFileSelected(!data || !data.slug ? 'all' : data.slug)
    if(step() == 'files') {
      getFiles()
    } else if (step() == 'stock') {
      getStockFiles()
    } else if (step() == 'brands') {
      getBrandFiles()
    }
  }

  const changeRoute = async (slug: string) => {
    setStep(slug)

    // Reset
    setCurrentFolder(null)
    setMyFiles(null)
    setStockFiles(null)
    setShowDeletedFolder(false)
    setTypeFileSelected('all')
    setFilterAscDesc('desc')
    setFilterGridList('grid')
    setSearchData(null)
    setCurrentBrand(null)
    setSelectAllFiles(false)
    setSelectedFiles([])
    setBrandFiles(null)
    setBrandsElements(null)
    setDeletedBrandFiles(null)
    setDeletedBrands(null)
    setNewBrandData({
      id: null,
      name: null,
      thumbnail: null,
      url: null
    })

    if (slug == 'files') {
      setLoading(true)

      var [userFolders] = await Promise.all([
        getMyFolders()
      ])

      setMyFolders(userFolders)
      getFiles()
      getBrandsElements()
    } else if (slug == 'stock') {
      setLoading(true)

      var [baFolders] = await Promise.all([
        getStockFolders()
      ])

      defineStockFolders(baFolders)
      getStockFiles()
    } else if (slug == 'brands') {
      getBrandsElements()
    }
  }

  const defineStockFolders = (data) => {
    var newStockFoldersStructure = []
    data.forEach((x,i) => {
      if(typeof x == 'string') {
        let newData = {
          title: x, 
          folders: typeof data[i+1] == 'object' ? data[i+1] : null,
          show_secondary: false
        }
        newStockFoldersStructure.push(newData)
      }
    })
    setStockFolders(newStockFoldersStructure)
  }

  const changeUploadFile = (event) => {
    const files = Array.from(event.target.files)
    let validFiles = []
    let totalSizeMB = 0
  
    files.forEach(file => {
      const fileSizeMB = Math.ceil(file.size / (1024 * 1024))
      totalSizeMB += fileSizeMB
  
      if (fileSizeMB > 50) {
        alertMsg('ba-alert', translations['file-exceed-limit'])
      } else {
        validFiles.push(file)
      }
    })
  
    const availableQuota = quotas().released - quotas().in_use
    if (totalSizeMB >= availableQuota) {
      alertMsg('ba-error', translations['file-exceed-limit-to-upload'])
      event.target.value = ''
      return
    }
  
    if (validFiles.length > 0) {
      setNewUploadFile(validFiles)
    }
  }
  

  const changeUploadBrandLogo = (event) => {
    setSelectedImageToBrand(null)

    const file = event.target.files[0]
    if (file) {
      const fileSizeMB = Math.ceil(file.size / (1024 * 1024))

      if (fileSizeMB > 50) {
        alertMsg('ba-alert', translations['file-exceed-limit'])

        if (fileSizeMB >= (quotas().released - quotas().in_use)) {
          alertMsg('ba-error', translations['file-exceed-limit-to-upload'])
        }

        event.target.value = ''
      } else {
        setNewBrandLogo([file])
      }
    }
  }

  const getExtensionType = () => {
    const files = newUploadFile()

    if (!files || files.length === 0 || files.length > 1) return "OTHER"

    const extension = files[0].name.split('.').pop()?.toLowerCase()
    const types = [
      { ext: ['gif'], svg: 'GIF' },
      { ext: ['mp4', 'mkv', 'mov', 'avi', 'wmv', 'flv', 'webm'], svg: 'VIDEO' },
      { ext: ['png', 'jpg', 'jpeg', 'bmp', 'tiff', 'webp', 'svg'], svg: 'IMAGE' },
      { ext: ['mp3', 'wav', 'aac', 'flac', 'ogg', 'm4a'], svg: 'AUDIO' }
    ]
    const type = types.find(t => t.ext.includes(extension))

    return type ? type.svg : 'OTHER'
  }

  const sendMoveFile = async () => {
    setLoading(true)
    let hasError = false

    const promises = selectedFiles().map(async (file) => {
      try {
        const body = {
          to: selectedFolderToMoveFile(),
          hash: file
        }
        const { success } = await sendGFRequest(props.token, `files/move`, 'PUT', body)
        if (!success) hasError = true
      } catch (error) {
        hasError = true
      }
    })

    await Promise.all(promises)
    setLoading(false)

    if (hasError) {
      alertMsg('ba-error', translations['toolkit-error-to-move'])
    } else {
      alertMsg('ba-success', translations['toolkit-success-to-move'])
    }

    setCurrentFolder(selectedFolderToMoveFile())
    organizeMyFoldersScroll()
    hideMoveFilePopup()
    getFiles()
  }

  const sendMoveFileToBrand = async () => {
    setLoading(true)
    let hasError = false

    const promises = selectedFiles().map(async (file) => {
      const currentFile = myFiles().find(x => x.hash == file)
      try {
        var body = {
          brand_id: selectedBrandToMoveFile().id,
          file_id: currentFile.id,
        }
    
        const { success } = await sendGFRequest(props.token, `brand-files/create`, 'POST', body)
        if (!success) hasError = true
      } catch (error) { 
        hasError = true
      }
    })

    await Promise.all(promises)
    setLoading(false)

    if (hasError) {
      alertMsg('ba-error', translations['toolkit-error-to-add'])
    } else {
      alertMsg('ba-success', translations['toolkit-success-to-add'])
    }

    hideMoveMyFileToBrandPopup()
    setSelectedBrandToMoveFile(null)
    getFiles()
  }

  const sendMoveBrandFile = async () => {
    setLoading(true)
    let hasError = false

    const promises = selectedFiles().map(async (file) => {
      const currentFile = brandFiles().find(x => x.hash == file)
      try {
        const body = {
          file_id: currentFile.id,
          from: currentBrand().id,
          to: selectedBrandToMoveFile().id
        }
        const { success } = await sendGFRequest(props.token, `brand-files/move`, 'PUT', body)
        if (!success) hasError = true
      } catch (error) {
        hasError = true
      }
    })

    await Promise.all(promises)
    setLoading(false)

    if (hasError) {
      alertMsg('ba-error', translations['toolkit-error-to-move'])
    } else {
      alertMsg('ba-success', translations['toolkit-success-to-move'])
    }

    hideMoveBrandFilePopup()
    getBrandFiles()
  }

  const sendMergeBrand = async () => {
    setLoading(true)

    const body = {
      from: brandToDeleteAndMerge().id,
      to: selectedBrandToMerge().id
    }
    const { success } = await sendGFRequest(props.token, `brands/merge`, 'PUT', body)

    if (success){
      alertMsg('ba-success', translations['toolkit-success-to-merge-brand'])
    } else {
      alertMsg('ba-error', translations['toolkit-error-to-brand-merge'])
    }
    setLoading(false)


    hideMergeBrandPopup()
    getBrandsElements()
  }

  const sendUploadedFile = async () => {
    setShowUploadFilePopup(false)
    var uploadedFile = []

    var files = !isEmpty(newBrandLogo()) && step() == 'brands' ? newBrandLogo() : newUploadFile()

    const uploadPromises = files.map(file => {
      return new Promise((resolve, reject) => {
        setUploadLoading(true)

        const folderToUpload = step() == 'files' ? selectedFolderToUploadFile() ?? translations['General'] : translations['toolkit-brands']

        var formData = new FormData()
        formData.append('folder', folderToUpload)
        formData.append('file', file)

        var xhr = new XMLHttpRequest()
        xhr.open('POST', `${process.env.GLOBAL_FILE_API}/v1/files/upload`, true)
        xhr.setRequestHeader('Authorization', `Bearer ${props.token}`)

        xhr.upload.onprogress = function (event) {
          if (event.lengthComputable) {
            var percentCompleted = Math.round((event.loaded * 100) / event.total)
            setPercentCompleted(percentCompleted)
          }
        }

        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(null)
            uploadedFile.push(JSON.parse(xhr.response).data)
          } else {
            console.error('Erro ao enviar o arquivo. Status:', xhr.status)
            alertMsg('ba-error', translations['toolkit-error-upload-file'])
            setUploadLoading(false)
            reject()
          }
        }

        xhr.onerror = function () {
          console.error('Erro na requisição de upload')
          alertMsg('ba-error', translations['toolkit-error-upload-file'])
          setUploadLoading(false)
          reject()
        }

        xhr.send(formData)
      })
    })

    try {
      await Promise.all(uploadPromises)

      setUploadLoading(false)
      if(step() == 'files') {
        alertMsg('ba-success', translations['toolkit-success-upload'])
        setTimeout(() => {
          setTypeFileSelected('all')
          getFiles()
          setPercentCompleted(0)
          setNewUploadFile([])
          setCurrentFolder(selectedFolderToUploadFile())
          organizeMyFoldersScroll()
          setSelectedFolderToUploadFile(null)
          if(isEmpty(currentFolder())) {
            getMyFolders()
          }
        }, 500)
      } else if (step() == 'brands' && !isEmpty(uploadedFile)) {
        if(showUpdateBrandPopup() || showNewBrandPopup()){
          setNewBrandLogo(uploadedFile[0].url)
        } else {
          uploadedFile.forEach(f => {
            createBrandFileLink(currentBrand().id, f.id)
          })
        }
      }
      
    } catch (error) {
      console.error('Erro durante o upload de um ou mais arquivos:', error)
    }

    return true
  }

  const openUploadPopupAndHideBrand = () => {
    hideUpdateBrandPopup()
    hideNewBrandPopup()
    changeRoute('files')
    openUploadPopup()
  }

  const sendNewThumbToBrand = () => {
    setShowSelectImagesToBrandPopup(false)
    setSearchData(null)
  }

  const openUploadPopup = () => {
    setShowUploadFilePopup(true)
    setSelectedFolderToUploadFile(currentFolder())

    // Organize scroll position
    const webComponent = document.querySelector("builderall-digital-file-repository")
    const shadowRoot = webComponent?.shadowRoot
    if (shadowRoot) {
      const container = shadowRoot.getElementById('folders-to-make-upload')
      const activeElement = container.querySelector(".active")

      if (activeElement) {
        activeElement.scrollIntoView({ behavior: "smooth", block: "nearest" })
      }
    }
  }

  const changeSelectedFile = (data, verify = false) => {
    setSelectedFiles((prev) => {
      const fileHash = isEmpty(data.hash) ? data.id : data.hash

      if (verify) {
        return prev.includes(fileHash) ? prev : [...prev, fileHash]
      } else {
        return prev.includes(fileHash) ? prev.filter((hash) => hash !== fileHash) : [...prev, fileHash]
      }
    })

    var conditions = [
      { step: 'files',  condition: showDeletedFolder() ? !isEmpty(selectedFiles()) && !isEmpty(myDeletedFiles()) && selectedFiles().length == myDeletedFiles().length : !isEmpty(selectedFiles()) && !isEmpty(myFiles()) && selectedFiles().length == myFiles().length },
      { step: 'brands', condition: showDeletedFolder() ? (isEmpty(currentBrand()) ? (!isEmpty(selectedFiles()) && !isEmpty(deletedBrands()) && selectedFiles().length == deletedBrands().length) : (!isEmpty(selectedFiles()) && !isEmpty(deletedBrandFiles()) && selectedFiles().length == deletedBrandFiles().length)) : !isEmpty(selectedFiles()) && !isEmpty(brandFiles()) && selectedFiles().length == brandFiles().length },
      { step: 'stock',  condition: !isEmpty(selectedFiles()) && !isEmpty(stockFiles()) && selectedFiles().length == stockFiles().length },
    ]

    if (conditions.find(x => x.step == step()).condition) {
      setSelectAllFiles(true)
    } else {
      setSelectAllFiles(false)
    }
  }

  const changeSelectAllFiles = () => {
    const newSelectAll = !selectAllFiles()
    setSelectAllFiles(newSelectAll)
  
    if (!newSelectAll) {
      return setSelectedFiles([])
    }
    
    const stepHandlers = {
      files: () => showDeletedFolder() ? myDeletedFiles().map(x => x.hash) : myFiles().map(x => x.hash),
      stock: () => stockFiles().map(x => x.hash),
      brands: () => {
        if (showDeletedFolder()) {
          return isEmpty(currentBrand()) ? deletedBrands().map(x => x.id) : deletedBrandFiles().map(x => x.hash)
        }
        return brandFiles().map(x => x.hash)
      }
    }
  
    setSelectedFiles(stepHandlers[step()]?.() || [])
  }

  const changePagination = (index: number) => {
    if(step() == 'files') {
      getFiles(index)
    } else if (step() == 'stock') {
      getStockFiles(index)
    } else if (step() == 'brands') {
      getBrandFiles(index)
    }
  }

  const changeCurrentBrand = (brand) => {
    setCurrentBrand(brand)
    getBrandFiles()
  }

  const hideUploadFilePopup = () => {
    setShowUploadFilePopup(false)
    setSelectedFolderToUploadFile(null)
    setPercentCompleted(0)
    setNewUploadFile([])
  }

  const hideNewFolderPopup = () => {
    setShowNewFolderPopup(false)
    setNewFolderName(null)
  }

  const hideSelectImagesToBrandPopup = () => {
    setShowSelectImagesToBrandPopup(false)
    setSelectedImageToBrand(null)
    setSearchData(null)
  }

  const hideNewBrandPopup = () => {
    setShowNewBrandPopup(false)
    setCurrentBrand(null)
    setSelectedImageToBrand(null)
    setNewBrandLogo([])
    setNewBrandData({
      id: null,
      name: null,
      thumbnail: null,
      url: null
    })
  }

  const hideUpdateBrandPopup = () => {
    setShowUpdateBrandPopup(null)
    setSelectedImageToBrand(null)
    setNewBrandLogo([])
    setNewBrandData({
      id: null,
      name: null,
      thumbnail: null,
      url: null
    })
  }

  const hideDeleteFilePopup = () => {
    setShowDeleteFilePopup(false)
  }

  const hideUnlinkFileBrandPopup = () => {
    setShowUnlinkFileBrandPopup(false)
  }

  const hideMoveFilePopup = () => {
    setShowMoveFilePopup(false)
    setSelectedFolderToMoveFile(null)
  }

  const hideMoveBrandFilePopup = () => {
    setShowMoveBrandFilePopup(false)
    setSelectedBrandToMoveFile(null)
  }

  const hideMoveMyFileToBrandPopup = () => {
    setShowMoveFileToBrandPopup(false)
    setSelectedBrandToMoveFile(null)
  }

  const hideMergeBrandPopup = () => {
    setShowMergeBrandPopup(false)
    setBrandToDeleteAndMerge(null)
    setSelectedBrandToMerge(null)
  }

  const createBrandFileLink = async (brand_id, file_id) => {
    setLoading(true)

    var body = {
      brand_id: brand_id,
      file_id: file_id,
    }

    var { success } = await sendGFRequest(props.token, `brand-files/create`, 'POST', body)

    if (success) {
      alertMsg('ba-success', translations['toolkit-success-to-link-file'])
      hideUploadFilePopup()
      setBrandToDeleteAndMerge(null)
      getBrandFiles()
    } else {
      alertMsg('ba-error', translations['toolkit-error-to-link-file'])
    }

    setLoading(false)
  }

  const sendNewBrand = async () => {
    setLoading(true)
    const urlRegex = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(:[0-9]{1,5})?(\/.*)?$/i

    if (isEmpty(newBrandData().name)) {
      alertMsg('ba-error', translations['toolkit-enter-brand-name'])
      setLoading(false)
      return
    } else if(!isEmpty(newBrandData().url) && !urlRegex.test(newBrandData().url)){
      alertMsg('ba-error', translations['toolkit-invalid-url'])
      setLoading(false)
      return
    }

    if (!isEmpty(newBrandLogo())){
      await sendUploadedFile()
    }

    var body = {
      name: newBrandData().name,
      thumbnail: !isEmpty(newBrandLogo()) ? newBrandLogo() : selectedImageToBrand(),
      url: newBrandData().url
    }

    var { success } = await sendGFRequest(props.token, `brands/create`, 'POST', body)

    if (success) {
      alertMsg('ba-success', translations['toolkit-success-to-create-brand'])
      hideUpdateBrandPopup()
      hideNewBrandPopup()
      getBrandsElements()
    } else {
      alertMsg('ba-error', translations['toolkit-error-to-create-brand'])
    }

    setLoading(false)
  }

  const updateBrand = async () => {
    setLoading(true)
    const urlRegex = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(:[0-9]{1,5})?(\/.*)?$/i

    if (isEmpty(newBrandData().name)) {
      alertMsg('ba-error', translations['toolkit-enter-brand-name'])
      setLoading(false)
      return
    } else if(!isEmpty(newBrandData().url) && !urlRegex.test(newBrandData().url)){
      alertMsg('ba-error', translations['toolkit-invalid-url'])
      setLoading(false)
      return
    }

    if (!isEmpty(newBrandLogo())){
      await sendUploadedFile()
    }

    var body = {
      brand_id: newBrandData().id,
      name: newBrandData().name,
      thumbnail: !isEmpty(newBrandLogo()) ? newBrandLogo() : selectedImageToBrand(),
      url: newBrandData().url
    }

    var { success } = await sendGFRequest(props.token, `brands/update`, 'PUT', body)

    if (success) {
      alertMsg('ba-success', translations['toolkit-success-to-update-brand'])
      hideUpdateBrandPopup()
      hideNewBrandPopup()
      getBrandsElements()
    } else {
      alertMsg('ba-error', translations['toolkit-error-to-update-brand'])
    }

    setLoading(false)
  }

  const sendNewFolder = async () => {
    setLoading(true)

    if (isEmpty(newFolderName())) {
      alertMsg('ba-error', translations['toolkit-enter-folder-name'])
      setLoading(false)
      return
    } else if (folderAlreadyCreated()) {
      alertMsg('ba-error', translations['toolkit-folder-already-exists'])
      setLoading(false)
      return
    } else if (newFolderName().length > 50) {
      alertMsg('ba-error', translations['toolkit-folder-cannot-50c'])
      setLoading(false)
      return
    }

    var body = {
      folder: newFolderName()
    }

    var { success } = await sendGFRequest(props.token, `folders/create`, 'POST', body)

    if (success) {
      alertMsg('ba-success', translations['toolkit-success-to-create-folder'])

      var [userFolders] = await Promise.all([
        getMyFolders()
      ])

      setMyFolders(userFolders)
      setCurrentFolder(newFolderName())
      organizeMyFoldersScroll()
      getFiles()
      hideNewFolderPopup()
    } else {
      alertMsg('ba-error', translations['toolkit-error-to-create-folder'])
    }

    setLoading(false)
  }

  const folderAlreadyCreated = () => {
    if (!isEmpty(myFolders())) {
      const folderExists = myFolders().some(f => f.toString().toLowerCase() === newFolderName().toString().toLowerCase())
      return folderExists
    }
    return false
  }

  const sendDeleteFile = async () => {
    setLoading(true)
    let hasError = false
  
    const deletePromises = selectedFiles().map(async (hash) => {
      try {
        const success = await sendGFRequestWithData(`files/delete`, 'DELETE', { hash })
        if (!success) hasError = true
      } catch (error) {
        hasError = true
      }
    })
  
    await Promise.all(deletePromises)
    setLoading(false)
  
    if (hasError) {
      alertMsg('ba-error', translations['toolkit-error-to-delete-file'])
    } else {
      alertMsg('ba-success', translations['toolkit-success-to-delete-file'])
    }
  
    hideDeleteFilePopup()
    getFiles()
  }

  const sendUnlinkFileBrand = async () => {
    setLoading(true)
    let hasError = false
  
    const removePromises = selectedFiles().map(async (s) => {
      const file = brandFiles().find(x => x.hash === s)
      const body = { 
        brand_id: currentBrand().id, 
        file_id: file.id 
      }
  
      try {
        const success = await sendGFRequestWithData(`brand-files/delete`, 'DELETE', body)
        if (!success) hasError = true
      } catch (error) {
        hasError = true
      }
    })
  
    await Promise.all(removePromises)
  
    setLoading(false)
  
    if (hasError) {
      alertMsg('ba-error', translations['toolkit-error-to-unlink-file'])
    } else {
      alertMsg('ba-success', translations['toolkit-success-to-unlink-file'])
    }
  
    hideUnlinkFileBrandPopup()
    getBrandFiles()
  }

  const sendDeleteBrand = async () => {
    setLoading(true)

    const success = await sendGFRequestWithData(`brands/delete`, 'DELETE', { brand_id: brandToDeleteAndMerge().id })

    if(success) {
      alertMsg('ba-success', translations['toolkit-success-to-delete-brand'])
    } else {
      alertMsg('ba-error', translations['toolkit-error-to-delete-brand'])
    }

    setLoading(false)
    hideDeleteBrandPopup()
    getBrandsElements()
  }

  const hideDeleteFolderPopup = () => {
    setShowDeleteFolderPopup(false)
  }

  const hideDeleteBrandPopup = () => {
    setShowDeletedBrandPopup(false)
    setBrandToDeleteAndMerge(null)
  }

  const hideRenameFilePopup = () => {
    setShowRenameFilePopup(false)
    setNewFileName(null)
  }

  const changeSelectImagesToBrandPopup = () => {
    setShowSelectImagesToBrandPopup(true)
    getFiles()
  }

  const sendDeleteFolder = async () => {
    setLoading(true)
    var { success } = await sendGFRequest(props.token, `folders/delete?folder=${encodeURIComponent(currentFolder())}`, 'DELETE', null)
    setLoading(false)

    if (success) {
      alertMsg('ba-success', translations['toolkit-success-to-delete-folder'])

      hideDeleteFolderPopup()
      setCurrentFolder(null)
      var [userFolders] = await Promise.all([
        getMyFolders()
      ])
      setMyFolders(userFolders)
      getFiles()
      organizeMyFoldersScroll()
    } else {
      alertMsg('ba-success', translations['toolkit-error-to-delete-folder'])
    }
  }

  const sendRenameFile = async () => {
    setLoading(true)

    var body = {
      hash: selectedFiles()[0],
      new_filename: newFileName()
    }
    var { success } = await sendGFRequest(props.token, `files/update/filename`, 'PUT', body)
    setLoading(false)

    if(success) {
      alertMsg('ba-success', translations['toolkit-success-to-rename-file'])
      hideRenameFilePopup()
      if(step() == 'files') {
        getFiles()
      } else {
        getBrandFiles()
      }
    } else {
      alertMsg('ba-error', translations['toolkit-error-to-rename-file'])
    }
  }

  const defineSearchData = (e) => {
    setSearchData(e.target.value)

    if (searchTimer()) {
      clearInterval(searchTimer())
    }

    let timerToSearch = setTimeout(() => {
      if(step() == 'files') {
        getFiles()
      } else if (step() == 'stock') {
        getStockFiles()
      } else if (step() == 'brands') {
        if(showSelectImagesToBrandPopup()) {
          getFiles()
        }
        else if (!isEmpty(currentBrand())) {
          getBrandFiles()
        } else {
          getBrandsElements()
        }
      }
    }, 1500)

    setSearchTimer(timerToSearch)
  }

  const continueFile = async () => {
    const filesAccordingStep = step() == 'files' ? myFiles() : stockFiles()
    const file = filesAccordingStep.find(x => x.hash === selectedFiles()[0])
    const validFile = await validateFile(file)
    if (!validFile) {
      return false
    }

    const fileInput = document.getElementById(props.inputid) as HTMLInputElement
    if (fileInput) {
      fileInput.value = file.url
      const event = new Event('input', { bubbles: true })
      fileInput.dispatchEvent(event)
      closeModal()
    } else {
      console.error('Input to receive data not found.')
    }
  }

  function validateFile(file) {
    const extensions = props.accept.split(',')
    if (file?.hash && !validateFileExtension(file.hash, extensions)) {
      return false
    }
    return true
  }

  function validateFileExtension(fileName: string, allowedExtensions: string[]): boolean {
    const extension = fileName.split('.').pop()?.toLowerCase()
    return allowedExtensions.includes(`.${extension}`)
  }

  const alertMsg = (type, message) => {
    setToastData({
      type: type,
      message: message
    })

    setShowToast(true)

    const timer = setTimeout(() => {
      setShowToast(false)
    }, 5000)

    onCleanup(() => clearTimeout(timer))
  }

  const isEmpty = (val) => {
    return val === 0 || val === undefined || val == null || val.length <= 0 || (typeof val === 'object' && Object.keys(val).length === 0) ? true : false
  }

  const getQuotas = async () => {
    var data = await sendRequest(props.token, `profile/storage`)

    if (!isEmpty(data)) {
      return data
    }
  }

  const backBreadcrumbRoute = () => {
    setShowDeletedFolder(false)
    getBrandFiles()
  }

  const editImage = async (data) => {
    const customEvent = new CustomEvent("edit-image", {
      composed: true,
      detail: data.url,
    })
    document.dispatchEvent(customEvent)
  }

  const organizeMyFoldersScroll = () => {
    const webComponent = document.querySelector("builderall-digital-file-repository")
    const shadowRoot = webComponent?.shadowRoot
    if (shadowRoot) {
      const container = shadowRoot.getElementById('my-folders-list')
      const activeElement = container.querySelector(".active")

      if (activeElement) {
        activeElement.scrollIntoView({ behavior: "smooth", block: "nearest" })
      }
    }
  }

  const getMyFolders = async () => {
    var { data, success } = await sendGFRequest(props.token, 'folders/list', 'GET', null)

    if (success) {
      return data
    }
  }

  const getStockFolders = async () => {
    var { data, success } = await sendGFRequest(props.token, 'builderall-files/folders', 'GET', null)

    if (success) {
      return data
    }
  }

  const getBrandsElements = async () => {
    setLoading(true)

    const search = isEmpty(searchData()) ? '' : searchData()
    const orderBy = filterOrderBy().find(x => x.slug == filterAscDesc())
    const filters = `search=${search}&order_by=${orderBy.column}&order_direction=${orderBy.type}`

    var { data, success } = await sendGFRequest(props.token, `brands/list?${filters}`, 'GET', null)
    setLoading(false)

    if (success) {
      setBrandsElements(data)
    }
  }

  const getFiles = async (page: number = 1) => {
    setLoading(true)

    const typeFile = typeFileSelected() == 'all' ? '' : typeFileSelected()
    const folderName = encodeURIComponent(isEmpty(currentFolder()) ? '' : currentFolder())
    const search = isEmpty(searchData()) ? '' : searchData()
    const orderBy = filterOrderBy().find(x => x.slug == filterAscDesc())

    const filters = `folder=${folderName}&search=${search}&type=${typeFile}&order_by=${orderBy.column}&order_direction=${orderBy.type}&page=${page}`

    var { data, success, pagination } = await sendGFRequest(props.token, `files/paginated?${filters}`, 'GET', null)
    setLoading(false)

    if (success) {
      setMyFiles(data)
      setPagination(pagination)
      setSelectAllFiles(false)
      setSelectedFiles([])
    }
  }

  const getBrandFiles = async (page: number = 1) => {
    setLoading(true)

    const typeFile = typeFileSelected() == 'all' ? '' : typeFileSelected()
    const folderName = encodeURIComponent(isEmpty(currentFolder()) ? '' : currentFolder())
    const search = isEmpty(searchData()) ? '' : searchData()
    const orderBy = filterOrderBy().find(x => x.slug == filterAscDesc())

    const filters = `folder=${folderName}&search=${search}&type=${typeFile}&order_by=${orderBy.column}&order_direction=${orderBy.type}&page=${page}&brand_id=${currentBrand().id}`

    var { data, success, pagination } = await sendGFRequest(props.token, `files/paginated?${filters}`, 'GET', null)
    setLoading(false)

    if (success) {
      setBrandFiles(data)
      setPagination(pagination)
      setSelectAllFiles(false)
      setSelectedFiles([])
    }
  }

  const getStockFiles = async (page: number = 1) => {
    setLoading(true)

    const typeFile = typeFileSelected() == 'all' ? '' : typeFileSelected()
    const folderName = encodeURIComponent(isEmpty(currentFolder()) ? '' : currentFolder())
    const search = isEmpty(searchData()) ? '' : searchData()
    const orderBy = filterOrderBy().find(x => x.slug == filterAscDesc())
    const parent = isEmpty(folderName) && stockFolders().find(x => x.show_secondary) ? 'Images' : ''

    const filters = `folder=${folderName}&search=${search}&type=${typeFile}&order_by=${orderBy.column}&order_direction=${orderBy.type}&page=${page}&folder_parent=${parent}`

    var { data, success, pagination } = await sendGFRequest(props.token, `builderall-files/paginated?${filters}`, 'GET', null)
    setLoading(false)

    if (success) {
      setStockFiles(data)
      setPagination(pagination)
      setSelectAllFiles(false)
      setSelectedFiles([])
    }
  }

  const hardDeleteFiles = async () => {
    setLoading(true)
    let hasError = false

    var route = 'files/hard-delete'

    const promises = selectedFiles().map(async (file) => {
      if(step() == 'brands' && isEmpty(currentBrand())) {
        route = `brands/hard-delete?brand_id=${file}`
      }

      try {
        const body = { hash: file }
        const { success } = await sendGFRequest(props.token, route, 'DELETE', body)
        if (!success) hasError = true
      } catch (error) {
        hasError = true
      }
    })

    await Promise.all(promises)
    setLoading(false)

    if (hasError) {
      alertMsg('ba-error', translations['toolkit-error-to-delete'])
    } else {
      alertMsg('ba-success', translations['toolkit-success-to-delete'])
    }

    // Reset
    setSelectAllFiles(false)
    setSelectedFiles([])

    if(step() == 'files') {
      getDeletedFiles()
    } else if (step() == 'brands') {
      if(!isEmpty(currentBrand())){
        getDeletedBrandFiles()
      } else {
        getDeletedBrands()
      }
    }
  }

  const restoreFiles = async () => {
    setLoading(true)
    let hasError = false

    var route = 'trash/restore'

    const promises = selectedFiles().map(async (file) => {
      if(step() == 'brands' && isEmpty(currentBrand())) {
        route = `brands/restore?brand_id=${file}`
      }

      try {
        const body = { hash: file }
        const { success } = await sendGFRequest(props.token, route, 'PUT', body)
        if (!success) hasError = true
      } catch (error) {
        hasError = true
      }
    })

    await Promise.all(promises)
    setLoading(false)

    if (hasError) {
      alertMsg('ba-error', translations['toolkit-error-to-restore'])
    } else {
      alertMsg('ba-success', translations['toolkit-success-to-restore'])
    }

    // Reset
    setSelectAllFiles(false)
    setSelectedFiles([])

    if(step() == 'files') {
      getDeletedFiles()
    } else if (step() == 'brands') {
      if(!isEmpty(currentBrand())){
        getDeletedBrandFiles()
      } else {
        getDeletedBrands()
      }
    }
  }

  const getDeletedFiles = async () => {
    setLoading(true)

    var { data, success } = await sendGFRequest(props.token, `trash/list`, 'GET', null)

    setLoading(false)

    if (success) {
      setMyDeletedFiles(data)
      setSelectAllFiles(false)
      setSelectedFiles([])
    }
  }

  const getDeletedBrands = async () => {
    setLoading(true)

    var { data, success } = await sendGFRequest(props.token, `brands/list-deleted`, 'GET', null)

    setLoading(false)

    if (success) {
      setDeletedBrands(data)
      setSelectAllFiles(false)
      setSelectedFiles([])
    }
  }

  const getDeletedBrandFiles = async () => {
    setLoading(true)

    var { data, success } = await sendGFRequest(props.token, `trash/list?brand_id=${currentBrand().id}`, 'GET', null)

    setLoading(false)

    if (success) {
      setDeletedBrandFiles(data)
      setSelectAllFiles(false)
      setSelectedFiles([])
    }
  }

  const changeShowDeletedFolder = () => {
    setShowDeletedFolder(true)
    if(step() == 'files') {
      getDeletedFiles()
    } else if(step() == 'brands') {
      if(!isEmpty(currentBrand())){
        getDeletedBrandFiles()
      } else {
        getDeletedBrands()
      }
    }

    // Reset
    setTypeFileSelected('all')
    setCurrentFolder(null)
    setSelectAllFiles(false)
    setSelectedFiles([])
    setFilterAscDesc('desc')
    setFilterGridList('grid')
    setSearchData(null)
    setMyFiles(null)
    setBrandFiles(null)
    setBrandsElements(null)
  }

  const changeSecondaryMenuStatus = (data) => {
    setCurrentFolder(null)

    setStockFolders((prev) => 
      prev.map((folder) => 
        folder.title === data 
          ? { ...folder, show_secondary: !folder.show_secondary } 
          : folder
      )
    )
    if(stockFolders().find(x => x.show_secondary)) {
      getStockFiles()
    }
  }  

  const closeModal = () => {
    const customEvent = new CustomEvent("handle-close-global-file", {
      composed: true,
      detail: props
    })
    document.dispatchEvent(customEvent)
    props.show = false
    changeRoute('home')
  }

  const sendGFRequestWithData = async (path, method, body) => {
    var { success } = await sendGFRequest(props.token, path, method, body)

    return success
  }

  // Components
  function Breadcrumb() {
    return (
      <div class="breadcrumb">
        <button 
          innerHTML={breadcrumb.default}
          onClick={() => changeRoute('home')}
        ></button>
        <div innerHTML={breadcrumb.arrow}></div>
        <button
          onClick={() => changeRoute(routes().find(x => x.slug == step()).slug)}
        >
          {routes().find(x => x.slug == step()).title}
        </button>
        <Show when={step() == 'files'}>
          <div innerHTML={breadcrumb.arrow}></div>
          <button>{showDeletedFolder() ? translations['toolkit-deleted'] : isEmpty(currentFolder()) ? translations['toolkit-all'] : currentFolder()}</button>
        </Show>
        <Show when={step() == 'stock' && !isEmpty(stockFolders())}>
          <div innerHTML={breadcrumb.arrow}></div>
          <Show when={!isEmpty(currentFolder()) && isEmpty(stockFolders().find(x => x.show_secondary))} fallback={
            <Show when={isEmpty(stockFolders().find(x => x.show_secondary) && isEmpty(currentFolder()))} fallback={
              <div>
                <button>Images</button>
                <div innerHTML={breadcrumb.arrow}></div>
                <button>{isEmpty(currentFolder()) ? translations['toolkit-all'] : currentFolder()}</button>
              </div>
            }>
              <button>{translations['toolkit-all']}</button>
            </Show>
          }>
            <button>{currentFolder()}</button>
          </Show>
        </Show>
        <Show when={step() == 'brands'}>
          <Show when={!isEmpty(currentBrand())}>
            <div innerHTML={breadcrumb.arrow}></div>
            <button
              onClick={() => backBreadcrumbRoute()}
            >
              {currentBrand().name}
            </button>
          </Show>
          <Show when={showDeletedFolder()}>
            <div innerHTML={breadcrumb.arrow}></div>
            <button>{translations['toolkit-deleted']}</button>
          </Show>
        </Show>
      </div>
    )
  }

  function RenameFilePopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideRenameFilePopup()}
      >
        <div
          class="popup rename-file"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <h3 class="popup-title">{translations['toolkit-rename-file']}</h3>
          </div>
          <div class="popup-main-input">
            <label for="new-folder">{translations['toolkit-name']}</label>
            <input
              type="text"
              id="new-folder"
              value={newFileName()}
              onInput={(e) => setNewFileName(e.target.value)}
            />
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-transparent"
              onClick={() => hideRenameFilePopup()}
            >
              {translations['toolkit-cancel']}
            </button>
            <button
              class="ba-btn ba-blue"
              onClick={() => sendRenameFile()}
              disabled={isEmpty(newFileName())}
            >
              {translations['toolkit-save']}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function NewBrandPopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideNewBrandPopup()}
      >
        <div
          class="popup new-brand"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <h3 class="popup-title">{translations['toolkit-new-brand']}</h3>
          </div>
          <div class="popup-main-input">
            <div>
              <label for="brand-name">{translations['toolkit-brand-name']} <b class="ba-red-color">*</b></label>
              <input
                type="text"
                id="brand-name"
                value={newBrandData().name}
                onInput={(e) =>
                  setNewBrandData((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </div>
            <div class="logo-box">
              <label>{translations['toolkit-brand-logo']}</label>
              <div class="logo-elements">
                <label class="upload-element">
                  <div innerHTML={uploadPopup.upload}></div>
                  <input
                    type="file"
                    accept=".png,.jpg,.jpeg,.gif,.svg,.webp"
                    onChange={(e) => changeUploadBrandLogo(e)}
                  />
                  <span>{isEmpty(newBrandLogo()) ? translations['toolkit-info-upload-max-size'] : newBrandLogo()[0].name}</span>
                </label>
                <span>{translations['toolkit-or']}</span>
                <button 
                  class="ba-btn ba-blue"
                  onClick={() => changeSelectImagesToBrandPopup()}
                >
                  {translations['toolkit-select-image']}
                  <Show when={!isEmpty(selectedImageToBrand())}>
                    <span class="logo-name">{selectedImageToBrand()}</span>
                  </Show>
                </button>
              </div>
            </div>
            <div>
              <label for="brand-url">{translations['toolkit-website-url']}</label>
              <input
                type="text"
                id="brand-url"
                value={newBrandData().url}
                placeholder="https://domain.com"
                onInput={(e) =>
                  setNewBrandData((prev) => ({ ...prev, url: e.target.value }))
                }
              />
            </div>
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-transparent"
              onClick={() => hideNewBrandPopup()}
            >
              {translations['toolkit-cancel']}
            </button>
            <button
              class="ba-btn ba-blue"
              onClick={() => sendNewBrand()}
              disabled={isEmpty(newBrandData())}
            >
              {translations['toolkit-save']}
            </button>
          </div>
          <Show when={showSelectImagesToBrandPopup()}>
            <SelectImagesToBrand />
          </Show>
        </div>
      </div>
    )
  }

  function SelectImagesToBrand() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideSelectImagesToBrandPopup()}
      >
        <div
          class="popup select-images-to-brand"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <div class="two-elements">
              <h3 class="popup-title">{translations['toolkit-select-image']}</h3>
              <div class='ba-box-search-input'>
                <input
                  type='search'
                  class='ba-input'
                  value={searchData()}
                  onInput={(e) => defineSearchData(e)}
                  placeholder={translations['toolkit-search']+'...'}
                />
                <button
                  class='ba-button'
                  onClick={() => getFiles()}
                >
                  <builderall-icon code='search' />
                </button>
              </div>
            </div>
          </div>
          <builderall-loader loading={loading()}>
            <div class="popup-main-images-grid">
              <Show when={!isEmpty(myFiles())} fallback={
                <div class="no-content">
                  <span>{translations['toolkit-no-files-yet-click-to-add']}</span>
                  <button
                    class="ba-btn ba-blue"
                    onClick={() => openUploadPopupAndHideBrand()}
                  >
                    <builderall-icon code='2780'></builderall-icon>
                    {translations['toolkit-new-upload']}
                  </button>
                </div>
              }>
                <div class="images-grid">
                  <For each={myFiles()}>
                    {(item, index) => (
                      <>
                        <button
                          classList={{ 'active': !isEmpty(selectedImageToBrand()) && selectedImageToBrand() == item.url }}
                          onClick={() => { setSelectedImageToBrand(item.url), setNewBrandLogo(null) }}
                        >
                          {item.type === "IMAGE" || item.type === "VIDEO" || item.type === "GIF" ? (
                            <img
                              class="file-preview"
                              src={item.url}
                              onError={(e) => {
                                e.target.style.display = 'none'
                                e.target.nextSibling.style.display = 'block'
                              }}
                            />
                          ) : (
                            <div
                              class="file-icon"
                              innerHTML={previewSVGs[item.type]}
                            />
                          )}
                          <div
                            class="file-icon"
                            innerHTML={previewSVGs[item.type]}
                            style={{ display: 'none' }}
                          />
                        </button>
                      </>
                    )}
                  </For>
                </div>
              </Show>
            </div>
          </builderall-loader>
          <div class="popup-footer">
            <button
              class="ba-btn ba-transparent"
              onClick={() => hideSelectImagesToBrandPopup()}
            >
              {translations['toolkit-cancel']}
            </button>
            <button
              class="ba-btn ba-blue"
              onClick={() => sendNewThumbToBrand()}
              disabled={isEmpty(selectedImageToBrand())}
            >
              {translations['toolkit-select']}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function UpdateBrandPopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideUpdateBrandPopup()}
      >
        <div
          class="popup update-brand"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <h3 class="popup-title">{translations['toolkit-update-brand']}</h3>
          </div>
          <div class="popup-main-input">
            <div>
              <label for="brand-name">{translations['toolkit-brand-name']} <b class="ba-red-color">*</b></label>
              <input
                type="text"
                id="brand-name"
                value={newBrandData().name}
                onInput={(e) =>
                  setNewBrandData((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </div>
            <div class="logo-box">
              <label>{translations['toolkit-brand-logo']}</label>
              <div class="logo-elements">
                <label class="upload-element">
                  <div innerHTML={uploadPopup.upload}></div>
                  <input
                    type="file"
                    accept=".png,.jpg,.jpeg,.gif,.svg,.webp"
                    onChange={(e) => changeUploadBrandLogo(e)}
                  />
                  <span>{isEmpty(newBrandLogo()) ? translations['toolkit-info-upload-max-size'] : newBrandLogo()[0].name}</span>
                </label>
                <span>{translations['toolkit-or']}</span>
                <button 
                  class="ba-btn ba-blue"
                  onClick={() => changeSelectImagesToBrandPopup()}
                >
                  {translations['toolkit-select-image']}
                  <Show when={!isEmpty(selectedImageToBrand())}>
                    <span class="logo-name">{selectedImageToBrand()}</span>
                  </Show>
                </button>
              </div>
            </div>
            <div>
              <label for="brand-url">{translations['toolkit-website-url']}</label>
              <input
                type="text"
                id="brand-url"
                value={newBrandData().url}
                placeholder="https://domain.com"
                onInput={(e) =>
                  setNewBrandData((prev) => ({ ...prev, url: e.target.value }))
                }
              />
            </div>
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-transparent"
              onClick={() => hideUpdateBrandPopup()}
            >
              {translations['toolkit-cancel']}
            </button>
            <button
              class="ba-btn ba-blue"
              onClick={() => updateBrand()}
              disabled={isEmpty(newBrandData())}
            >
              {translations['toolkit-save']}
            </button>
          </div>
          <Show when={showSelectImagesToBrandPopup()}>
            <SelectImagesToBrand />
          </Show>
        </div>
      </div>
    )
  }

  function NewFolderPopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideNewFolderPopup()}
      >
        <div
          class="popup new-folder"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <h3 class="popup-title">{translations['toolkit-new-folder']}</h3>
          </div>
          <div class="popup-main-input">
            <label for="new-folder">{translations['toolkit-name']}</label>
            <input
              type="text"
              id="new-folder"
              value={newFolderName()}
              onInput={(e) => setNewFolderName(e.target.value)}
            />
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-transparent"
              onClick={() => hideNewFolderPopup()}
            >
              {translations['toolkit-cancel']}
            </button>
            <button
              class="ba-btn ba-blue"
              onClick={() => sendNewFolder()}
              disabled={isEmpty(newFolderName())}
            >
              {translations['toolkit-save']}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function DeleteFolderPopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideDeleteFolderPopup()}
      >
        <div
          class="popup delete-folder"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <builderall-icon code='info'></builderall-icon>
            <h3 class="popup-title">{translations['toolkit-delete-folder']}</h3>
          </div>
          <div class="popup-main">
            <span>{translations['toolkit-question-delete-folder'].replace('FOLDER_NAME', currentFolder())}</span>
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-white"
              onClick={() => hideDeleteFolderPopup()}
            >
              {translations['toolkit-cancel']}
            </button>
            <button
              class="ba-btn ba-red"
              onClick={() => sendDeleteFolder()}
            >
              {translations['toolkit-delete']}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function DeleteBrandPopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideDeleteBrandPopup()}
      >
        <div
          class="popup delete-brand"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <builderall-icon code='info'></builderall-icon>
            <h3 class="popup-title">{translations['tookit-delete-brand']}</h3>
          </div>
          <div class="popup-main">
            <span>{translations['toolkit-question-delete-brand'].replace('BRAND_NAME', brandToDeleteAndMerge().name)}</span>
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-white"
              onClick={() => hideDeleteBrandPopup()}
            >
              {translations['toolkit-cancel']}
            </button>
            <button
              class="ba-btn ba-red"
              onClick={() => sendDeleteBrand()}
            >
              {translations['toolkit-delete']}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function DeleteFilePopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideDeleteFilePopup()}
      >
        <div
          class="popup delete-file"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <builderall-icon code='info'></builderall-icon>
            <h3 class="popup-title">{translations['toolkit-delete-file']} ({selectedFiles().length})</h3>
          </div>
          <div class="popup-main">
            <span>{translations['toolkit-question-delete-files']}</span>
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-white"
              onClick={() => hideDeleteFilePopup()}
            >
              {translations['toolkit-cancel']}
            </button>
            <button
              class="ba-btn ba-red"
              onClick={() => sendDeleteFile()}
            >
              {translations['toolkit-delete']}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function UnlinkFileBrandPopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideUnlinkFileBrandPopup()}
      >
        <div
          class="popup delete-file"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <builderall-icon code='info'></builderall-icon>
            <h3 class="popup-title">{translations['toolkit-remove-from-brand']} ({selectedFiles().length})</h3>
          </div>
          <div class="popup-main">
            <span>{translations['toolkit-question-unlink-file-brand'].replace('BRAND_NAME', currentBrand().name)}</span>
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-white"
              onClick={() => hideUnlinkFileBrandPopup()}
            >
              {translations['toolkit-cancel']}
            </button>
            <button
              class="ba-btn ba-red"
              onClick={() => sendUnlinkFileBrand()}
            >
              {translations['toolkit-remove']}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function UploadFilePopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideUploadFilePopup()}
      >
        <div
          class="popup upload-file"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <h3 class="popup-title">{translations['toolkit-upload-file']}</h3>
            <Show when={step() == 'files'}>
              <p class="popup-description">{translations['toolkit-select-folder-to-upload-file']}</p>
            </Show>
          </div>
          <div class="popup-main">
            <label class="upload-element">
              <div innerHTML={uploadPopup.upload}></div>
              <input type="file" accept={props.accept} onChange={(e) => changeUploadFile(e)} multiple />
              <span>{isEmpty(newUploadFile()) ? translations['toolkit-info-upload-max-size'] : newUploadFile().map(x => x.name).join(', ')}</span>
            </label>
            <Show when={step() == 'files'}>
              <div class="folders-box">
                <div class="current-folder">
                  <div class="main-icon" innerHTML={sidebar.myFiles} />
                  <div innerHTML={breadcrumb.arrow}></div>
                  <span>{isEmpty(selectedFolderToUploadFile()) ? translations['toolkit-all'] : selectedFolderToUploadFile()}</span>
                </div>
                <hr />
                <div class="folders-list" id="folders-to-make-upload">
                  <button
                    classList={{ 'active': isEmpty(selectedFolderToUploadFile()) }}
                    onClick={() => setSelectedFolderToUploadFile(null)}
                  >
                    <div innerHTML={sidebar.myFiles}></div>
                    {translations['toolkit-all']}
                  </button>
                  <For each={myFolders()}>
                    {(item, index) => (
                      <>
                        <button
                          classList={{ 'active': selectedFolderToUploadFile() == item }}
                          onClick={() => setSelectedFolderToUploadFile(item)}
                        >
                          <div innerHTML={sidebar.myFiles}></div>
                          {item}
                        </button>
                      </>
                    )}
                  </For>
                </div>
              </div>
            </Show>
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-transparent"
              onClick={() => hideUploadFilePopup()}
            >
              {translations['toolkit-cancel']}
            </button>
            <button
              class="ba-btn ba-blue"
              onClick={() => sendUploadedFile()}
              disabled={isEmpty(newUploadFile())}
            >
              {translations['toolkit-save']}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function MoveFilePopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideMoveFilePopup()}
      >
        <div
          class="popup move-file"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <div class="two-elements">
              <h3 class="popup-title">{translations['toolkit-move-files']} ({selectedFiles().length})</h3>
              <button 
                class="ba-btn ba-blue"
                onClick={() => { hideMoveFilePopup(), setShowNewFolderPopup(true) }}
              >
                {translations['toolkit-new-folder']}
              </button>
            </div>
          </div>
          <div class="popup-main">
            <div class="current-folder">
              <div class="main-icon" innerHTML={sidebar.myFiles}></div>
              <span>{isEmpty(currentFolder()) ? translations['toolkit-all'] : currentFolder()}</span>
              <Show when={!isEmpty(selectedFolderToMoveFile())}>
                <div innerHTML={breadcrumb.arrow}></div>
                <span>{selectedFolderToMoveFile()}</span>
              </Show>
            </div>
            <hr />
            <div class="folders-box">
              <div class="folders-list" id="folders-to-move">
                <For each={myFolders().filter(x => x != currentFolder())}>
                  {(item, index) => (
                    <>
                      <button
                        classList={{ 'active': selectedFolderToMoveFile() == item }}
                        onClick={() => setSelectedFolderToMoveFile(item)}
                      >
                        <div innerHTML={sidebar.myFiles}></div>
                        {item}
                      </button>
                    </>
                  )}
                </For>
              </div>
            </div>
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-transparent"
              onClick={() => hideMoveFilePopup()}
            >
              {translations['toolkit-cancel']}
            </button>
            <button
              class="ba-btn ba-blue"
              onClick={() => sendMoveFile()}
              disabled={isEmpty(selectedFolderToMoveFile())}
            >
              {translations['toolkit-move']}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function MoveMyFileToBrandPopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideMoveMyFileToBrandPopup()}
      >
        <div
          class="popup move-file"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <div class="two-elements">
              <h3 class="popup-title">{translations['toolkit-add-to-brand']}</h3>
            </div>
          </div>
          <div class="popup-main">
            <div class="current-folder">
              <div class="main-icon" innerHTML={sidebar.myFiles}></div>
              <span>({selectedFiles().length})</span>
              <Show when={!isEmpty(selectedBrandToMoveFile())}>
                <div innerHTML={breadcrumb.arrow}></div>
                <span>{selectedBrandToMoveFile().name}</span>
              </Show>
            </div>
            <hr />
            <div class="folders-box">
              <div class="folders-list" id="folders-to-move">
                <For each={brandsElements()}>
                  {(item, index) => (
                    <>
                      <button
                        classList={{ 'active': selectedBrandToMoveFile() == item }}
                        onClick={() => setSelectedBrandToMoveFile(item)}
                      >
                        <div innerHTML={sidebar.myFiles}></div>
                        {item.name}
                      </button>
                    </>
                  )}
                </For>
              </div>
            </div>
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-transparent"
              onClick={() => hideMoveMyFileToBrandPopup()}
            >
              {translations['toolkit-cancel']}
            </button>
            <button
              class="ba-btn ba-blue"
              onClick={() => sendMoveFileToBrand()}
              disabled={isEmpty(selectedBrandToMoveFile())}
            >
              {translations['toolkit-add']}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function MoveBrandFilePopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideMoveBrandFilePopup()}
      >
        <div
          class="popup move-file"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <div class="two-elements">
              <h3 class="popup-title">{translations['toolkit-move-files']} ({selectedFiles().length})</h3>
            </div>
          </div>
          <div class="popup-main">
            <div class="current-folder">
              <div class="main-icon" innerHTML={sidebar.myFiles}></div>
              <span>{currentBrand().name}</span>
              <Show when={!isEmpty(selectedBrandToMoveFile())}>
                <div innerHTML={breadcrumb.arrow}></div>
                <span>{selectedBrandToMoveFile().name}</span>
              </Show>
            </div>
            <hr />
            <div class="folders-box">
              <div class="folders-list" id="folders-to-move">
                <For each={brandsElements().filter(x => x != currentBrand())}>
                  {(item, index) => (
                    <>
                      <button
                        classList={{ 'active': selectedBrandToMoveFile() == item }}
                        onClick={() => setSelectedBrandToMoveFile(item)}
                      >
                        <div innerHTML={sidebar.myFiles}></div>
                        {item.name}
                      </button>
                    </>
                  )}
                </For>
              </div>
            </div>
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-transparent"
              onClick={() => hideMoveBrandFilePopup()}
            >
              {translations['toolkit-cancel']}
            </button>
            <button
              class="ba-btn ba-blue"
              onClick={() => sendMoveBrandFile()}
              disabled={isEmpty(selectedBrandToMoveFile())}
            >
              {translations['toolkit-move']}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function MergeBrandPopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideMergeBrandPopup()}
      >
        <div
          class="popup merge-brand"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <div class="two-elements">
              <h3 class="popup-title">{translations['toolkit-brand-merge']}</h3>
            </div>
          </div>
          <div class="popup-main">
            <div class="current-folder">
              <div class="main-icon" innerHTML={sidebar.myFiles}></div>
              <span>{brandToDeleteAndMerge().name}</span>
              <Show when={!isEmpty(selectedBrandToMerge())}>
                <div innerHTML={breadcrumb.arrow}></div>
                <span>{selectedBrandToMerge().name}</span>
              </Show>
            </div>
            <hr />
            <div class="folders-box">
              <div class="folders-list" id="folders-to-move">
                <For each={brandsElements().filter(x => x.name != brandToDeleteAndMerge().name)}>
                  {(item, index) => (
                    <>
                      <button
                        classList={{ 'active': selectedBrandToMerge() == item }}
                        onClick={() => setSelectedBrandToMerge(item)}
                      >
                        <div innerHTML={sidebar.myFiles}></div>
                        {item.name}
                      </button>
                    </>
                  )}
                </For>
              </div>
            </div>
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-transparent"
              onClick={() => hideMergeBrandPopup()}
            >
              {translations['toolkit-cancel']}
            </button>
            <button
              class="ba-btn ba-blue"
              onClick={() => sendMergeBrand()}
              disabled={isEmpty(selectedBrandToMerge())}
            >
              {translations['toolkit-merge']}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function Pagination() {
    return (
      <nav class='ba-pagination ba-md'>
        <ul class='ba-list'>
          <Show when={pagination().current_page > 1}>
            <li class='ba-nav-item'>
              <a
                onClick={() => changePagination(pagination().current_page - 1)}
                class='ba-back'
              >
                <span class='ba-icon'>
                  <builderall-icon code='arrow-left' />
                </span>
              </a>
            </li>
          </Show>
          <Show when={pagination().last_page > 6} fallback={
            <For each={[...Array(pagination().last_page).keys()].map(i => i + 1)}>
              {(page, index) => (
                <li class='ba-nav-item'>
                  <a
                    onClick={() => changePagination(page)}
                    classList={{ 'ba-link': true, 'ba-is-active': pagination().current_page == page }}
                  >
                    {page}
                  </a>
                </li>
              )}
            </For>
          }>
            <li class='ba-nav-item'>
              <a
                onClick={() => changePagination(1)}
                classList={{ 'ba-link': true, 'ba-is-active': pagination().current_page == 1 }}
              >1</a>
            </li>
            <li class='ba-nav-item'>
              <a
                onClick={() => changePagination(2)}
                classList={{ 'ba-link': true, 'ba-is-active': pagination().current_page == 2 }}
              >2</a>
            </li>
            ...
            <Show when={pagination().current_page != 1 && pagination().current_page != 2 && pagination().current_page != pagination().last_page - 1 && pagination().current_page != pagination().last_page}>
            <li class='ba-nav-item'>
              <a
                onClick={() => changePagination(2)}
                class="ba-link ba-is-active"
              >{pagination().current_page}</a>
            </li>
            ...
            </Show>
            <li class='ba-nav-item'>
              <a
                onClick={() => changePagination(pagination().last_page - 1)}
                classList={{ 'ba-link': true, 'ba-is-active': pagination().current_page == pagination().last_page - 1 }}
              >
                {pagination().last_page - 1}
              </a>
            </li>
            <li class='ba-nav-item'>
              <a
                onClick={() => changePagination(pagination().last_page)}
                classList={{ 'ba-link': true, 'ba-is-active': pagination().current_page == pagination().last_page }}
              >
                {pagination().last_page}
              </a>
            </li>
          </Show>
          <Show when={pagination().current_page != pagination().last_page}>
            <li class='ba-nav-item'>
              <a
                onClick={() => changePagination(pagination().current_page + 1)}
                class='ba-next'
              >
                <span class='ba-icon'>
                  <builderall-icon code='arrow-right' />
                </span>
              </a>
            </li>
          </Show>
        </ul>
      </nav>
    )
  }

  function FilesNav() {
    return (
      <div class="files-nav">
        <For each={fileTypes()}>
          {(item, index) => (
            <>
              <button
                classList={{ 'active': typeFileSelected() == item.slug }}
                innerHTML={filesNav[item.slug]}
                onClick={() => changeTypeFile(item)}
                ba-tooltip-position='bottom'
                ba-tooltip-title={item.title}
                ba-tooltip-color='dark'
              ></button>
            </>
          )}
        </For>
      </div>
    )
  }

  function BrandsFilter() {
    return (
      <Show when={!loading()}>
        <div class="files-filters">
          <div class="right-filters">
            <button class="btn-open-select">
              <div>
                <div class="current-icon" innerHTML={filesFilters[filterOrderBy().find(x => x.slug == filterAscDesc()).type]}></div>
                {filterOrderBy().find(x => x.slug == filterAscDesc()).title}
              </div>
              <div class="arrow-icon" innerHTML={filesFilters.arrow}></div>
            </button>
            <div class="select-box">
              <button onClick={() => changeFilterAscDesc('asc')}>
                {translations['toolkit-ascending']}
              </button>
              <button onClick={() => changeFilterAscDesc('desc')}>
                {translations['toolkit-descending']}
              </button>
              <button onClick={() => changeFilterAscDesc('az')}>
                A-z
              </button>
              <button onClick={() => changeFilterAscDesc('za')}>
                Z-a
              </button>
            </div>
          </div>
        </div>
      </Show>
    )
  }

  function StockFilter() {
    return (
      <Show when={!loading()}>
        <div class="files-filters">
          <div class="left-filters">
            <label>
              <div
                class="checkbox"
                innerHTML={selectAllFiles() ? checkbox.checked : checkbox.checkbox}
              ></div>
              <input type="checkbox" onInput={() => changeSelectAllFiles()} />
              {translations['toolkit-select-all']}
            </label>
          </div>
          <div class="right-filters">
            <button class="btn-open-select">
              <div>
                <div class="current-icon" innerHTML={filesFilters[filterOrderBy().find(x => x.slug == filterAscDesc()).type]}></div>
                {filterOrderBy().find(x => x.slug == filterAscDesc()).title}
              </div>
              <div class="arrow-icon" innerHTML={filesFilters.arrow}></div>
            </button>
            <div class="select-box">
              <button onClick={() => changeFilterAscDesc('asc')}>
                {translations['toolkit-ascending']}
              </button>
              <button onClick={() => changeFilterAscDesc('desc')}>
                {translations['toolkit-descending']}
              </button>
              <button onClick={() => changeFilterAscDesc('largest')}>
                {translations['toolkit-size-largest']}
              </button>
              <button onClick={() => changeFilterAscDesc('smallest')}>
                {translations['toolkit-size-smallest']}
              </button>
              <button onClick={() => changeFilterAscDesc('az')}>
                A-z
              </button>
              <button onClick={() => changeFilterAscDesc('za')}>
                Z-a
              </button>
            </div>
            <button
              classList={{ 'btn-grid-list': true, 'active': filterGridList() == 'grid' }}
              innerHTML={filesFilters.grid}
              onClick={() => setFilterGridList('grid')}
            ></button>
            <button
              classList={{ 'btn-grid-list': true, 'active': filterGridList() == 'list' }}
              innerHTML={filesFilters.list}
              onClick={() => setFilterGridList('list')}
            ></button>
          </div>
        </div>
      </Show>
    )
  }

  function FilesFilter() {
    return (
      <Show when={!loading()}>
        <div class="files-filters">
          <div class="left-filters">
            <label>
              <div
                class="checkbox"
                innerHTML={selectAllFiles() ? checkbox.checked : checkbox.checkbox}
              ></div>
              <input type="checkbox" onInput={() => changeSelectAllFiles()} />
              {translations['toolkit-select-all']}
            </label>
            <Show when={!isEmpty(selectedFiles()) && selectedFiles().length > 0}>
              <button 
                innerHTML={filesFilters.move}
                onClick={() => setShowMoveFilePopup(true)}
              ></button>
              <button 
                innerHTML={filesActions.delete} 
                onClick={() => setShowDeleteFilePopup(true)}
              ></button>
              <Show when={!isEmpty(brandsElements()) && brandsElements().length > 0}>
                <button 
                  innerHTML={filesActions.movetobrand} 
                  onClick={() => setShowMoveFileToBrandPopup(true)}
                ></button>
              </Show>
            </Show>
          </div>
          <div class="right-filters">
            <button class="btn-open-select">
              <div>
                <div class="current-icon" innerHTML={filesFilters[filterOrderBy().find(x => x.slug == filterAscDesc()).type]}></div>
                {filterOrderBy().find(x => x.slug == filterAscDesc()).title }
              </div>
              <div class="arrow-icon" innerHTML={filesFilters.arrow}></div>
            </button>
            <div class="select-box">
              <button onClick={() => changeFilterAscDesc('asc')}>
                {translations['toolkit-ascending']}
              </button>
              <button onClick={() => changeFilterAscDesc('desc')}>
                {translations['toolkit-descending']}
              </button>
              <button onClick={() => changeFilterAscDesc('largest')}>
                {translations['toolkit-size-largest']}
              </button>
              <button onClick={() => changeFilterAscDesc('smallest')}>
                {translations['toolkit-size-smallest']}
              </button>
              <button onClick={() => changeFilterAscDesc('az')}>
                A-z
              </button>
              <button onClick={() => changeFilterAscDesc('za')}>
                Z-a
              </button>
            </div>
            <button
              classList={{ 'btn-grid-list': true, 'active': filterGridList() == 'grid' }}
              innerHTML={filesFilters.grid}
              onClick={() => setFilterGridList('grid')}
            ></button>
            <button
              classList={{ 'btn-grid-list': true, 'active': filterGridList() == 'list' }}
              innerHTML={filesFilters.list}
              onClick={() => setFilterGridList('list')}
            ></button>
          </div>
        </div>
      </Show>
    )
  }

  function BrandElementsFilter() {
    return (
      <Show when={!loading()}>
        <div class="files-filters">
          <div class="left-filters">
            <label>
              <div
                class="checkbox"
                innerHTML={selectAllFiles() ? checkbox.checked : checkbox.checkbox}
              ></div>
              <input type="checkbox" onInput={() => changeSelectAllFiles()} />
              {translations['toolkit-select-all']}
            </label>
            <Show when={!isEmpty(selectedFiles()) && selectedFiles().length > 0}>
              <button 
                innerHTML={filesFilters.move}
                onClick={() => { setShowMoveBrandFilePopup(true) }}
              ></button>
              <button 
                innerHTML={filesActions.unlink} 
                onClick={() => setShowUnlinkFileBrandPopup(true)}
              ></button>
            </Show>
          </div>
          <div class="right-filters">
            <button class="btn-open-select">
              <div>
                <div class="current-icon" innerHTML={filesFilters[filterOrderBy().find(x => x.slug == filterAscDesc()).type]}></div>
                {filterOrderBy().find(x => x.slug == filterAscDesc()).title }
              </div>
              <div class="arrow-icon" innerHTML={filesFilters.arrow}></div>
            </button>
            <div class="select-box">
              <button onClick={() => changeFilterAscDesc('asc')}>
                {translations['toolkit-ascending']}
              </button>
              <button onClick={() => changeFilterAscDesc('desc')}>
                {translations['toolkit-descending']}
              </button>
              <button onClick={() => changeFilterAscDesc('largest')}>
                {translations['toolkit-size-largest']}
              </button>
              <button onClick={() => changeFilterAscDesc('smallest')}>
                {translations['toolkit-size-smallest']}
              </button>
              <button onClick={() => changeFilterAscDesc('az')}>
                A-z
              </button>
              <button onClick={() => changeFilterAscDesc('za')}>
                Z-a
              </button>
            </div>
            <button
              classList={{ 'btn-grid-list': true, 'active': filterGridList() == 'grid' }}
              innerHTML={filesFilters.grid}
              onClick={() => setFilterGridList('grid')}
            ></button>
            <button
              classList={{ 'btn-grid-list': true, 'active': filterGridList() == 'list' }}
              innerHTML={filesFilters.list}
              onClick={() => setFilterGridList('list')}
            ></button>
          </div>
        </div>
      </Show>
    )
  }

  function FilesList() {
    return (
      <Show when={!loading()}>
        <div class="files-grid-list-box">
          <Show when={filterGridList() == 'grid'} fallback={
            <div class="files-list">
              <Show when={!isEmpty(myFiles()) || loading()} fallback={
                <div class="no-content">
                  <span>{translations['toolkit-no-files-yet-click-to-add']}</span>
                  <button
                    class="ba-btn ba-blue"
                    onClick={() => openUploadPopup()}
                  >
                    <builderall-icon code='2780'></builderall-icon>
                    {translations['toolkit-new-upload']}
                  </button>
                </div>
              }>
                <For each={myFiles()}>
                  {(item, index) => (
                    <>
                      <label class="file-element">
                        <div class="file-el-left">
                          <div
                            class="checkbox"
                            innerHTML={selectedFiles().includes(item.hash) ? checkbox.checked : checkbox.checkbox}
                          ></div>
                          <input
                            type="checkbox"
                            onInput={() => changeSelectedFile(item)}
                            checked={selectedFiles().includes(item.hash)}
                          />
                          {item.type === "IMAGE" || item.type === "VIDEO" || item.type === "GIF" ? (
                            <img
                              class="file-preview"
                              src={item.url}
                              onError={(e) => {
                                e.target.style.display = 'none'
                                e.target.nextSibling.style.display = 'block'
                              }}
                            />
                          ) : (
                            <div
                              class="file-icon"
                              innerHTML={previewSVGs[item.type]}
                            />
                          )}
                          <div
                            class="file-icon"
                            innerHTML={previewSVGs[item.type]}
                            style={{ display: 'none' }}
                          />
                          <span class="file-name">{item.name}</span>
                        </div>
                        <div class="file-el-right">
                          <button
                            innerHTML={filesActions.rename}
                            onClick={() => { changeSelectedFile(item), setShowRenameFilePopup(true) }}
                          ></button>
                          <Show
                            when={
                              (props.showeditoption || props.showeditoption == 'true') &&
                              ['png', 'jpg', 'jpeg'].includes(item.hash.split('.').pop()?.toLowerCase())
                            }
                          >
                            <button 
                              innerHTML={filesActions.edit}
                              onClick={() => editImage(item)}
                            ></button>
                          </Show>
                          <a
                            href={item.url}
                            target="_blank"
                            innerHTML={filesActions.preview}
                          ></a>
                          <button
                            class="btn-delete"
                            innerHTML={filesActions.delete}
                            onClick={() => { changeSelectedFile(item, true), setShowDeleteFilePopup(true) }}
                          ></button>
                        </div>
                      </label>
                    </>
                  )}
                </For>
              </Show>
            </div>
          }>
            <div class="files-grid">
              <Show when={!isEmpty(myFiles()) || loading()} fallback={
                <div class="no-content">
                  <span>{translations['toolkit-no-files-yet-click-to-add']}</span>
                  <button
                    class="ba-btn ba-blue"
                    onClick={() => openUploadPopup()}
                  >
                    <builderall-icon code='2780'></builderall-icon>
                    {translations['toolkit-new-upload']}
                  </button>
                </div>
              }>
                <For each={myFiles()}>
                  {(item, index) => (
                    <>
                      <label class="file-element">
                        <div
                          class="checkbox"
                          innerHTML={selectedFiles().includes(item.hash) ? checkbox.checked : checkbox.checkbox}
                        ></div>
                        <input
                          type="checkbox"
                          onInput={() => changeSelectedFile(item)}
                          checked={selectedFiles().includes(item.hash)}
                        />
                        {item.type === "IMAGE" || item.type === "VIDEO" || item.type === "GIF" ? (
                          <img
                            class="file-preview"
                            src={item.url}
                            onError={(e) => {
                              e.target.style.display = 'none'
                              e.target.nextSibling.style.display = 'block'
                            }}
                          />
                        ) : (
                          <div
                            class="file-icon"
                            innerHTML={previewSVGs[item.type]}
                          />
                        )}
                        <div
                          class="file-icon"
                          innerHTML={previewSVGs[item.type]}
                          style={{ display: 'none' }}
                        />
                        <span class="file-name">{item.name}</span>
                        <div class="file-hover-actions">
                          <button
                            innerHTML={filesActions.rename}
                            onClick={() => { changeSelectedFile(item), setShowRenameFilePopup(true) }}
                          ></button>
                          <Show
                            when={
                              (props.showeditoption || props.showeditoption == 'true') &&
                              ['png', 'jpg', 'jpeg'].includes(item.hash.split('.').pop()?.toLowerCase())
                            }
                          >
                            <button 
                              innerHTML={filesActions.edit}
                              onClick={() => editImage(item)}
                            ></button>
                          </Show>
                          <a 
                            href={item.url} 
                            target="_blank" 
                            innerHTML={filesActions.preview}
                          ></a>
                          <button 
                            innerHTML={filesActions.delete} 
                            onClick={() => { changeSelectedFile(item, true), setShowDeleteFilePopup(true) }}
                          ></button>
                        </div>
                      </label>
                    </>
                  )}
                </For>
              </Show>
            </div>
          </Show>
          <Show when={!loading() && pagination() && pagination().last_page > 1}>
            <Pagination />
          </Show>
        </div>
      </Show>
    )
  }

  function StockList() {
    return (
      <Show when={!loading()}>
        <div class="files-grid-list-box">
          <Show when={filterGridList() == 'grid'} fallback={
            <div class="files-list">
              <Show when={!isEmpty(stockFiles()) || loading()} fallback={
                <div class="no-content">
                  <span>{translations['toolkit-no-files-yet']}</span>
                </div>
              }>
                <For each={stockFiles()}>
                  {(item, index) => (
                    <>
                      <label class="file-element">
                        <div class="file-el-left">
                          <div
                            class="checkbox"
                            innerHTML={selectedFiles().includes(item.hash) ? checkbox.checked : checkbox.checkbox}
                          ></div>
                          <input
                            type="checkbox"
                            onInput={() => changeSelectedFile(item)}
                            checked={selectedFiles().includes(item.hash)}
                          />
                          {item.type === "IMAGE" || item.type === "VIDEO" || item.type === "GIF" ? (
                            <img
                              class="file-preview"
                              src={item.url}
                              onError={(e) => {
                                e.target.style.display = 'none'
                                e.target.nextSibling.style.display = 'block'
                              }}
                            />
                          ) : (
                            <div
                              class="file-icon"
                              innerHTML={previewSVGs[item.type]}
                            />
                          )}
                          <div
                            class="file-icon"
                            innerHTML={previewSVGs[item.type]}
                            style={{ display: 'none' }}
                          />
                          <span class="file-name">{item.name}</span>
                        </div>
                        <div class="file-el-right">
                          <a
                            href={item.url}
                            target="_blank"
                            innerHTML={filesActions.preview}
                          ></a>
                        </div>
                      </label>
                    </>
                  )}
                </For>
              </Show>
            </div>
          }>
            <div class="files-grid">
              <Show when={!isEmpty(stockFiles()) || loading()} fallback={
                <div class="no-content">
                  <span>{translations['toolkit-no-files-yet']}</span>
                </div>
              }>
                <For each={stockFiles()}>
                  {(item, index) => (
                    <>
                      <label class="file-element">
                        <div
                          class="checkbox"
                          innerHTML={selectedFiles().includes(item.hash) ? checkbox.checked : checkbox.checkbox}
                        ></div>
                        <input
                          type="checkbox"
                          onInput={() => changeSelectedFile(item)}
                          checked={selectedFiles().includes(item.hash)}
                        />
                        {item.type === "IMAGE" || item.type === "VIDEO" || item.type === "GIF" ? (
                          <img
                            class="file-preview"
                            src={item.url}
                            onError={(e) => {
                              e.target.style.display = 'none'
                              e.target.nextSibling.style.display = 'block'
                            }}
                          />
                        ) : (
                          <div
                            class="file-icon"
                            innerHTML={previewSVGs[item.type]}
                          />
                        )}
                        <div
                          class="file-icon"
                          innerHTML={previewSVGs[item.type]}
                          style={{ display: 'none' }}
                        />
                        <span class="file-name">{item.name}</span>
                        <div class="file-hover-actions">
                          <a 
                            href={item.url} 
                            target="_blank" 
                            innerHTML={filesActions.preview}
                          ></a>
                        </div>
                      </label>
                    </>
                  )}
                </For>
              </Show>
            </div>
          </Show>
          <Show when={!loading() && pagination() && pagination().last_page > 1}>
            <Pagination />
          </Show>
        </div>
      </Show>
    )
  }

  function BrandFilesList() {
    return (
      <Show when={!loading()}>
        <div class="files-grid-list-box files">
          <Show when={filterGridList() == 'grid'} fallback={
            <div class="files-list">
              <Show when={!isEmpty(brandFiles()) || loading()} fallback={
                <div class="no-content">
                  <span>{translations['tookit-empty-files-brand']}</span>
                  <button
                    class="ba-btn ba-blue"
                    onClick={() => openUploadPopup()}
                  >
                    <builderall-icon code='2780'></builderall-icon>
                    {translations['toolkit-new-content']}
                  </button>
                </div>
              }>
                <For each={brandFiles()}>
                  {(item, index) => (
                    <>
                      <label class="file-element">
                        <div class="file-el-left">
                          <div
                            class="checkbox"
                            innerHTML={selectedFiles().includes(item.hash) ? checkbox.checked : checkbox.checkbox}
                          ></div>
                          <input
                            type="checkbox"
                            onInput={() => changeSelectedFile(item)}
                            checked={selectedFiles().includes(item.hash)}
                          />
                          {item.type === "IMAGE" || item.type === "VIDEO" || item.type === "GIF" ? (
                            <img
                              class="file-preview"
                              src={item.url}
                              onError={(e) => {
                                e.target.style.display = 'none'
                                e.target.nextSibling.style.display = 'block'
                              }}
                            />
                          ) : (
                            <div
                              class="file-icon"
                              innerHTML={previewSVGs[item.type]}
                            />
                          )}
                          <div
                            class="file-icon"
                            innerHTML={previewSVGs[item.type]}
                            style={{ display: 'none' }}
                          />
                          <span class="file-name">{item.name}</span>
                        </div>
                        <div class="file-el-right">
                          <button
                            innerHTML={filesActions.rename}
                            onClick={() => { changeSelectedFile(item), setShowRenameFilePopup(true) }}
                          ></button>
                          <Show
                            when={
                              (props.showeditoption || props.showeditoption == 'true') &&
                              ['png', 'jpg', 'jpeg'].includes(item.hash.split('.').pop()?.toLowerCase())
                            }
                          >
                            <button 
                              innerHTML={filesActions.edit}
                              onClick={() => editImage(item)}
                            ></button>
                          </Show>
                          <a
                            href={item.url}
                            target="_blank"
                            innerHTML={filesActions.preview}
                          ></a>
                          <button
                            class="btn-delete unlink"
                            innerHTML={filesActions.unlink}
                            onClick={() => { changeSelectedFile(item, true), setShowUnlinkFileBrandPopup(true) }}
                          ></button>
                        </div>
                      </label>
                    </>
                  )}
                </For>
              </Show>
            </div>
          }>
            <div class="files-grid">
              <Show when={!isEmpty(brandFiles()) || loading()} fallback={
                <div class="no-content">
                  <span>{translations['tookit-empty-files-brand']}</span>
                  <button
                    class="ba-btn ba-blue"
                    onClick={() => openUploadPopup()}
                  >
                    <builderall-icon code='2780'></builderall-icon>
                    {translations['toolkit-new-content']}
                  </button>
                </div>
              }>
                <For each={brandFiles()}>
                  {(item, index) => (
                    <>
                      <label class="file-element">
                        <div
                          class="checkbox"
                          innerHTML={selectedFiles().includes(item.hash) ? checkbox.checked : checkbox.checkbox}
                        ></div>
                        <input
                          type="checkbox"
                          onInput={() => changeSelectedFile(item)}
                          checked={selectedFiles().includes(item.hash)}
                        />
                        {item.type === "IMAGE" || item.type === "VIDEO" || item.type === "GIF" ? (
                          <img
                            class="file-preview"
                            src={item.url}
                            onError={(e) => {
                              e.target.style.display = 'none'
                              e.target.nextSibling.style.display = 'block'
                            }}
                          />
                        ) : (
                          <div
                            class="file-icon"
                            innerHTML={previewSVGs[item.type]}
                          />
                        )}
                        <div
                          class="file-icon"
                          innerHTML={previewSVGs[item.type]}
                          style={{ display: 'none' }}
                        />
                        <span class="file-name">{item.name}</span>
                        <div class="file-hover-actions">
                          <button
                            innerHTML={filesActions.rename}
                            onClick={() => { changeSelectedFile(item), setShowRenameFilePopup(true) }}
                          ></button>
                          <Show
                            when={
                              (props.showeditoption || props.showeditoption == 'true') &&
                              ['png', 'jpg', 'jpeg'].includes(item.hash.split('.').pop()?.toLowerCase())
                            }
                          >
                            <button 
                              innerHTML={filesActions.edit}
                              onClick={() => editImage(item)}
                            ></button>
                          </Show>
                          <a 
                            href={item.url} 
                            target="_blank" 
                            innerHTML={filesActions.preview}
                          ></a>
                          <button 
                            class="unlink"
                            innerHTML={filesActions.unlink} 
                            onClick={() => { changeSelectedFile(item, true), setShowUnlinkFileBrandPopup(true) }}
                          ></button>
                        </div>
                      </label>
                    </>
                  )}
                </For>
              </Show>
            </div>
          </Show>
          <Show when={!loading() && pagination() && pagination().last_page > 1}>
            <Pagination />
          </Show>
        </div>
      </Show>
    )
  }

  function BrandsList() {
    return (
      <Show when={!loading()}>
        <div class="files-grid-list-box elements">
         <div class="files-grid">
            <Show when={!isEmpty(brandsElements()) || loading()} fallback={
              <div class="no-content">
                <span>{translations['toolkit-no-files-yet']}</span>
              </div>
            }>
              <For each={brandsElements()}>
                {(item, index) => (
                  <>
                    <div 
                      class="brand-element"
                      onClick={() => changeCurrentBrand(item)}
                    >
                      <div class="box-file-preview">
                        <img
                          class="file-preview"
                          src={item.thumbnail}
                        />
                        <div class="brand-hover-actions">
                          <button
                            onClick={(e) => { setNewBrandData(item), setSelectedImageToBrand(item.thumbnail), setShowUpdateBrandPopup(true), e.stopPropagation() }}
                          >
                            <builderall-icon code='settings'></builderall-icon>
                          </button>
                          <Show when={!isEmpty(brandsElements()) && brandsElements().length > 1}>
                            <button
                              onClick={(e) => { setBrandToDeleteAndMerge(item), setShowMergeBrandPopup(true), e.stopPropagation()}}
                            >
                              <builderall-icon code='fda8'></builderall-icon>
                            </button>
                          </Show>
                          <button
                            onClick={(e) => { setBrandToDeleteAndMerge(item), setShowDeletedBrandPopup(true), e.stopPropagation()}}
                          >
                            <builderall-icon code='trash'></builderall-icon>
                          </button>
                        </div>
                      </div>
                      <div class="brand-infos">
                        <div class="brand-name-url">
                          <span class="file-name">{item.name}</span>
                          <Show when={item.url}>
                            <a 
                              class="file-url"
                              href={item.url} 
                              target="_blank"
                            >
                              {item.url}
                            </a>
                          </Show>
                        </div>
                        <button class="brand-access-btn">
                          <builderall-icon code='edit'></builderall-icon>
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </For>
            </Show>
          </div>
        </div>
      </Show>
    )
  }

  // Views
  function Welcome() {
    return (
      <div class="main welcome">
        <div class="box-image">
          <img src={welcomeImage} />
          <Show when={!loading()}>
            <p>{translations['toolkit-resume']}</p>
            <div class="welcome-actions">
              <button
                class="card"
                onClick={() => { changeRoute('files'), openUploadPopup() }}
              >
                <div class="logo upload">
                  <div innerHTML={welcomeToolkit.upload} />
                </div>
                <span class="title">{translations['toolkit-upload']}</span>
              </button>
              <button class="card" disabled>
                <div class="logo ai">
                  <div innerHTML={welcomeToolkit.ai} />
                </div>
                <span class="title">{translations['toolkit-ai-builder']}</span>
                <div class="coming-soon">
                  <div innerHTML={welcomeToolkit.tab} />
                  <span>{translations['toolkit-coming-soon']}</span>
                </div>

              </button>
              <button
                class="card"
                onClick={() => changeRoute('files')}
              >
                <div class="logo files">
                  <div innerHTML={welcomeToolkit.files} />
                </div>
                <span class="title">{translations['toolkit-my-files']}</span>
              </button>
              <button
                class="card"
                onClick={() => changeRoute('brands')}
              >
                <div class="logo brands">
                  <div innerHTML={welcomeToolkit.brands} />
                </div>
                <span class="title">{translations['toolkit-my-brands']}</span>
              </button>
            </div>
          </Show>
        </div>
      </div>
    )
  }

  function Files() {
    return (
      <div class="main files">
        <div class="sidebar-files">
          <div class="files-header">
            <div class="my-files">
              <div innerHTML={sidebar.myFiles} />
              <span>{translations['toolkit-my-files']}</span>
            </div>
            <button class="btn-arrow" innerHTML={sidebar.arrow} />
          </div>
          <hr />
          <div class="files-main" id="my-folders-list">
            <button
              classList={{ 'btn-folder': true, 'active': !showDeletedFolder() && currentFolder() == null }}
              onClick={() => changeCurrentFolder(null)}
              title={translations['toolkit-all']}
            >
              <div class="folder-icon-title">
                <div innerHTML={sidebar.allFolders} />
                <span class="folder-title">{translations['toolkit-all']}</span>
              </div>
            </button>
            <For each={myFolders()}>
              {(item, index) => (
                <>
                  <button
                    classList={{ 'btn-folder': true, 'active': !showDeletedFolder() && currentFolder() == item }}
                    onClick={() => changeCurrentFolder(item)}
                    title={item}
                  >
                    <div class="folder-icon-title">
                      <div innerHTML={sidebar.allFolders} />
                      <span class="folder-title">{item}</span>
                    </div>
                    <div
                      class="btn-folder-trash"
                      innerHTML={sidebar.trash}
                      onClick={() => setShowDeleteFolderPopup(true)}
                    ></div>
                  </button>
                </>
              )}
            </For>
          </div>
          <div class="files-footer">
            <button
              class="ba-btn ba-grey new-folder"
              onClick={() => setShowNewFolderPopup(true)}
            >
              {translations['toolkit-new-folder']}
            </button>
          </div>
        </div>
        <Show when={showDeletedFolder()} fallback={
          <div class="files-content">
            <Breadcrumb />
            <div class="files-nav-search">
              <FilesNav />
              <div class="search-new">
                <div class='ba-box-search-input'>
                  <input
                    type='search'
                    class='ba-input'
                    value={searchData()}
                    onInput={(e) => defineSearchData(e)}
                    placeholder={translations['toolkit-search']+'...'}
                  />
                  <button
                    class='ba-button'
                    onClick={() => getFiles()}
                  >
                    <builderall-icon code='search' />
                  </button>
                </div>
                <button
                  class="ba-btn ba-blue"
                  onClick={() => openUploadPopup()}
                >
                  <builderall-icon code='2780'></builderall-icon>
                  {translations['toolkit-new-upload']}
                </button>
              </div>
            </div>
            <Show when={!isEmpty(myFiles()) && myFiles().length > 0}>
              <FilesFilter />
            </Show>
            <FilesList />
          </div>
        }>
          <Show when={!loading()}>
            <div class="trash-content">
              <Breadcrumb />
              <div class="red-alert">
                <div innerHTML={alerts.red} />
                <span>{translations['toolkit-info-trash-30-days']}</span>
              </div>
              <label class="select-all-label">
                <div
                  class="checkbox"
                  innerHTML={selectAllFiles() ? checkbox.checked : checkbox.checkbox}
                ></div>
                <input type="checkbox" onInput={() => changeSelectAllFiles()} />
                {translations['toolkit-select-all']}
              </label>
              <div class="files-grid">
                <Show when={!isEmpty(myDeletedFiles()) || loading()} fallback={
                  <div class="no-content">
                    <span>{translations['toolkit-no-deleted-files-yet']}</span>
                  </div>
                }>
                  <For each={myDeletedFiles().filter(x => x.hard_delete_in > 0)}>
                    {(item, index) => (
                      <>
                        <label class="file-element">
                          <div
                            class="checkbox"
                            innerHTML={selectedFiles().includes(item.hash) ? checkbox.checked : checkbox.checkbox}
                          ></div>
                          <input
                            type="checkbox"
                            onInput={() => changeSelectedFile(item)}
                            checked={selectedFiles().includes(item.hash)}
                          />
                          {item.type === "IMAGE" || item.type === "VIDEO" || item.type === "GIF" ? (
                            <img
                              class="file-preview"
                              src={item.url}
                              onError={(e) => {
                                e.target.style.display = 'none'
                                e.target.nextSibling.style.display = 'block'
                              }}
                            />
                          ) : (
                            <div
                              class="file-icon"
                              innerHTML={previewSVGs[item.type]}
                            />
                          )}
                          <div
                            class="file-icon"
                            innerHTML={previewSVGs[item.type]}
                            style={{ display: 'none' }}
                          />
                          <span 
                            class="days-to-delete"
                            classList={{ 'red': item.hard_delete_in < 5 }}
                          >
                            {item.hard_delete_in} {translations['toolkit-days']}
                          </span>
                          <div class="file-hover-actions">
                            <a 
                              href={item.url} 
                              target="_blank" 
                              innerHTML={filesActions.preview}
                            ></a>
                          </div>
                        </label>
                      </>
                    )}
                  </For>
                </Show>
              </div>
            </div>
          </Show>
        </Show>
      </div>
    )
  }

  function Stock() {
    return (
      <div class="main stock">
        <div class="sidebar-files">
          <div class="files-header">
            <div class="my-files">
              <div innerHTML={sidebar.myFiles} />
              <span>{translations['toolkit-stock-elements']}</span>
            </div>
            <button class="btn-arrow" innerHTML={sidebar.arrow} />
          </div>
          <hr />
          <div class="files-main" id="stock-folders-list">
            <For each={stockFolders()}>
              {(item, index) => (
                <>
                  <button
                    classList={{ 'btn-folder': true, 'active': item.show_secondary || item.title == currentFolder() }}
                    onClick={() => !isEmpty(item.folders) ? changeSecondaryMenuStatus(item.title) : changeCurrentFolder(item.title) }
                    title={item.title}
                  >
                    <div class="folder-icon-title">
                      <div innerHTML={sidebar.allFolders} />
                      <span class="folder-title">{item.title}</span>
                    </div>
                    <Show when={!isEmpty(item.folders)}>
                      <div class="btn-arrow" innerHTML={sidebar.arrow} />
                    </Show>
                  </button>
                  <Show when={item.show_secondary && !isEmpty(item.folders)}>
                    <div class="secondary-menu">
                      <For each={item.folders}>
                      {(s, index) => (
                        <>
                          <button 
                            classList={{ 'btn-folder': true, 'active': s == currentFolder() }}
                            onClick={() => changeCurrentFolder(s, 'secondary')}
                            title={s}
                          >
                            <div class="folder-icon-title">
                              <div innerHTML={sidebar.allFolders} />
                              <span class="folder-title">{s}</span>
                            </div>
                          </button>
                        </>
                      )}
                      </For>
                    </div>
                  </Show>
                </>
              )}
            </For>
          </div>
        </div>
        <div class="files-content">
          <Breadcrumb />
          <div class="files-nav-search">
            <FilesNav />
            <div class="search-new">
              <div class='ba-box-search-input'>
                <input
                  type='search'
                  class='ba-input'
                  value={searchData()}
                  onInput={(e) => defineSearchData(e)}
                  placeholder={translations['toolkit-search']+'...'}
                />
                <button
                  class='ba-button'
                  onClick={() => getFiles()}
                >
                  <builderall-icon code='search' />
                </button>
              </div>
            </div>
          </div>
          <Show when={!isEmpty(stockFiles()) && stockFiles().length > 0}>
            <StockFilter />
          </Show>
          <StockList />
        </div>
      </div>
    )
  }

  function Brands() {
    return (
      <div class="main brands">
        <Show when={!loading()}>
          <Show when={showDeletedFolder()} fallback={
            <Show when={!isEmpty(currentBrand())} fallback={
              <div class="files-content">
                <Breadcrumb />
                <div class="files-nav-search right">
                  <div class='ba-box-search-input'>
                    <input
                      type='search'
                      class='ba-input'
                      value={searchData()}
                      onInput={(e) => defineSearchData(e)}
                      placeholder={translations['toolkit-search']+'...'}
                    />
                    <button
                      class='ba-button'
                      onClick={() => getBrandFiles()}
                    >
                      <builderall-icon code='search' />
                    </button>
                  </div>
                  <Show when={!isEmpty(brandsElements()) && brandsElements().length > 0}>
                    <BrandsFilter />
                  </Show>
                  <button 
                    class="ba-btn ba-blue"
                    onClick={() => setShowNewBrandPopup(true)}
                  >
                    <builderall-icon code='2780'></builderall-icon>
                    {translations['toolkit-new-brand']}
                  </button>
                </div>
                <BrandsList />
              </div>
            }>
              <div class="files-content">
                <Breadcrumb />
                <div class="files-nav-search">
                  <FilesNav />
                  <div class="search-new">
                    <div class='ba-box-search-input'>
                      <input
                        type='search'
                        class='ba-input'
                        value={searchData()}
                        onInput={(e) => defineSearchData(e)}
                        placeholder={translations['toolkit-search']+'...'}
                      />
                      <button
                        class='ba-button'
                        onClick={() => getBrandFiles()}
                      >
                        <builderall-icon code='search' />
                      </button>
                    </div>
                    <button
                      class="ba-btn ba-blue"
                      onClick={() => openUploadPopup()}
                    >
                      <builderall-icon code='2780'></builderall-icon>
                      {translations['toolkit-new-content']}
                    </button>
                  </div>
                </div>
                <Show when={!isEmpty(brandFiles()) && brandFiles().length > 0}>
                  <BrandElementsFilter />
                </Show>
                <BrandFilesList />
              </div>
            </Show>
          }>
            <Show when={isEmpty(currentBrand())} fallback={
              <div class="trash-content files">
                <Breadcrumb />
                <div class="red-alert">
                  <div innerHTML={alerts.red} />
                  <span>{translations['toolkit-info-trash-30-days']}</span>
                </div>
                <label class="select-all-label">
                  <div
                    class="checkbox"
                    innerHTML={selectAllFiles() ? checkbox.checked : checkbox.checkbox}
                  ></div>
                  <input type="checkbox" onInput={() => changeSelectAllFiles()} />
                  {translations['toolkit-select-all']}
                </label>
                <div class="files-grid">
                  <Show when={!isEmpty(deletedBrandFiles()) || loading()} fallback={
                    <div class="no-content">
                      <span>{translations['toolkit-no-deleted-files-yet']}</span>
                    </div>
                  }>
                    <For each={deletedBrandFiles().filter(x => x.hard_delete_in > 0)}>
                      {(item, index) => (
                        <>
                          <label class="file-element">
                            <div
                              class="checkbox"
                              innerHTML={selectedFiles().includes(item.hash) ? checkbox.checked : checkbox.checkbox}
                            ></div>
                            <input
                              type="checkbox"
                              onInput={() => changeSelectedFile(item)}
                              checked={selectedFiles().includes(item.hash)}
                            />
                            {item.type === "IMAGE" || item.type === "VIDEO" || item.type === "GIF" ? (
                              <img
                                class="file-preview"
                                src={item.url}
                                onError={(e) => {
                                  e.target.style.display = 'none'
                                  e.target.nextSibling.style.display = 'block'
                                }}
                              />
                            ) : (
                              <div
                                class="file-icon"
                                innerHTML={previewSVGs[item.type]}
                              />
                            )}
                            <div
                              class="file-icon"
                              innerHTML={previewSVGs[item.type]}
                              style={{ display: 'none' }}
                            />
                            <span 
                              class="days-to-delete"
                              classList={{ 'red': item.hard_delete_in < 5 }}
                            >
                              {item.hard_delete_in} {translations['toolkit-days']}
                            </span>
                            <div class="file-hover-actions">
                              <a 
                                href={item.url} 
                                target="_blank" 
                                innerHTML={filesActions.preview}
                              ></a>
                            </div>
                          </label>
                        </>
                      )}
                    </For>
                  </Show>
                </div>
              </div>
            }>
              <div class="trash-content elements">
                <Breadcrumb />
                <div class="red-alert">
                  <div innerHTML={alerts.red} />
                  <span>{translations['toolkit-info-brand-trash-30-days']}</span>
                </div>
                <label class="select-all-label">
                  <div
                    class="checkbox"
                    innerHTML={selectAllFiles() ? checkbox.checked : checkbox.checkbox}
                  ></div>
                  <input type="checkbox" onInput={() => changeSelectAllFiles()} />
                  {translations['toolkit-select-all']}
                </label>
                <div class="files-grid">
                  <Show when={!isEmpty(deletedBrands()) || loading()} fallback={
                    <div class="no-content">
                      <span>{translations['toolkit-no-deleted-brands-yet']}</span>
                    </div>
                  }>
                    <For each={deletedBrands().filter(x => x.hard_delete_in > 0)}>
                      {(item, index) => (
                        <>
                          <label class="brand-element">
                            <div
                              class="checkbox"
                              innerHTML={selectedFiles().includes(item.id) ? checkbox.checked : checkbox.checkbox}
                            ></div>
                            <input
                              type="checkbox"
                              onInput={() => changeSelectedFile(item)}
                              checked={selectedFiles().includes(item.id)}
                            />
                            <div class="box-file-preview">
                              <img
                                class="file-preview"
                                src={item.thumbnail}
                              />
                              <span 
                                class="days-to-delete"
                                classList={{ 'red': item.hard_delete_in < 5 }}
                              >
                                {item.hard_delete_in} {translations['toolkit-days']}
                              </span>
                            </div>
                            <div class="brand-infos">
                              <div class="brand-name-url">
                                <span class="file-name">{item.name}</span>
                                <Show when={item.url}>
                                  <a 
                                    class="file-url"
                                    href={item.url} 
                                    target="_blank"
                                  >
                                    {item.url}
                                  </a>
                                </Show>
                              </div>
                            </div>
                          </label>
                        </>
                      )}
                    </For>
                  </Show>
                </div>
              </div>
            </Show>
          </Show>
        </Show>
      </div>
    )
  }

  return (
    <>
      <Show when={props.show}>
        <style>{require("bundle-text:./styles/index.scss")}</style>
        <div class="modal-backdrop global-file-repository">
          <div class="modal">
            <div class="modal-header">
              <span class="ba-title">Business Toolkit</span>
              <Show when={uploadLoading() && !isEmpty(percentCompleted())}>
                <div class="upload-progress">
                  <div innerHTML={anotherSVGs[getExtensionType()]} />
                  <div class='ba-progress'>
                    <Show when={!isEmpty(newUploadFile())}>
                      <span class='ba-progress-title'>{newUploadFile()[0].name}</span>
                    </Show>
                    <div class="ba-progress-content">
                      <progress class='ba-progress-bar' value={percentCompleted()} max='100'></progress>
                      <span class='ba-progress-title'>{percentCompleted()}%</span>
                    </div>
                  </div>
                </div>
              </Show>
              <button
                onClick={() => closeModal()}
                class="ba-btn btn-close"
              >
                <builderall-icon code='64f7' />
              </button>
            </div>
            <builderall-loader loading={loading()}>
              <div class="modal-content">
                <div class="sidebar">
                  <nav>
                    <button
                      classList={{ 'ba-btn': true, 'active': step() == 'home' }}
                      onClick={() => changeRoute('home')}
                    >
                      <div innerHTML={sidebar.home} />
                      <span>{translations['toolkit-home']}</span>
                    </button>
                    <button
                      classList={{ 'ba-btn': true, 'active': step() == 'files' }}
                      onClick={() => changeRoute('files')}
                    >
                      <div innerHTML={sidebar.files} />
                      <span>{translations['toolkit-my-files']}</span>
                    </button>
                    <button
                      classList={{ 'ba-btn': true, 'active': step() == 'stock' }}
                      onClick={() => changeRoute('stock')}
                    >
                      <div innerHTML={sidebar.stock} />
                      <span>{translations['toolkit-stock-elements']}</span>
                    </button>
                    <button
                      classList={{ 'ba-btn': true, 'active': step() == 'brands' }}
                      onClick={() => changeRoute('brands')}
                    >
                      <div innerHTML={sidebar.brands} />
                      <span>{translations['toolkit-my-brands']}</span>
                    </button>
                    <button 
                      class="ba-btn" 
                      disabled
                      ba-tooltip-position="bottom"
                      ba-tooltip-title={translations['toolkit-coming-soon']}
                      ba-tooltip-color="dark"
                    >
                      <div innerHTML={sidebar.magic} />
                      <span>{translations['toolkit-ai-builder']}</span>
                    </button>
                  </nav>
                </div>
                {step() == 'home' && <Welcome />}
                {step() == 'files' && <Files />}
                {step() == 'stock' && <Stock />}
                {step() == 'brands' && <Brands />}
              </div>
            </builderall-loader>
            <div class="modal-footer">
              <div class="left-actions">
                <a
                  class='ba-progress'
                  href="https://office.builderall.com/us/office/edit-my-account#quotas"
                  target="_blank"
                >
                  <progress
                    classList={{ 'ba-progress-bar': true, 'ba-limit-alert': quotas().in_use > quotas().released }}
                    value={quotas().released > quotas().in_use ? quotas().in_use : quotas().released}
                    max={quotas().released > quotas().in_use ? quotas().released : quotas().in_use}
                  ></progress>
                  <span class='ba-progress-data'>
                    {(quotas().in_use / 1024).toFixed(2)}gb of {(quotas().released / 1024).toFixed(2)}gb
                  </span>
                </a>
                <Show when={['files', 'brands'].includes(step()) && !loading() && isEmpty(currentBrand())}>
                  <button
                    classList={{ 'ba-btn deleted bold': true, 'active': showDeletedFolder() }}
                    onClick={() => changeShowDeletedFolder()}
                  >
                    <div innerHTML={sidebar.trash} />
                    <span>{translations['toolkit-deleted']}</span>
                  </button>
                </Show>
              </div>
              <div class="right-actions">
                <button
                  onClick={() => closeModal()}
                  class="ba-btn ba-white bold"
                >
                  {translations['toolkit-close-toolkit']}
                </button>
                <Show when={step() == 'files' || step() == 'stock' || (step() == 'brands')}>
                  <Show when={!props.hideapplybutton && !showDeletedFolder() && !(step() === 'brands' && isEmpty(currentBrand()))}>
                    <button
                      class="ba-btn ba-blue bold"
                      ba-tooltip-position="top"
                      ba-tooltip-title={selectedFiles().length > 1 || (!isEmpty(myFiles()) && !validateFile(myFiles().find(x => x.hash === selectedFiles()[0]))) ? translations['files-are-not-format'] : null}
                      ba-tooltip-color="dark"
                      onClick={() => continueFile()}
                      disabled={isEmpty(selectedFiles()) || selectedFiles().length > 1 || (!isEmpty(myFiles()) && !validateFile(myFiles().find(x => x.hash === selectedFiles()[0])))}
                    >
                      {translations['apply']}
                      {!isEmpty(selectedFiles()) && selectedFiles().length > 0 ? ` (${selectedFiles().length})` : ''}
                    </button>
                  </Show>
                  <Show when={showDeletedFolder()}>
                    <button
                      class="ba-btn ba-blue bold"
                      onClick={() => restoreFiles()}
                      disabled={isEmpty(selectedFiles())}
                    >
                      {translations['toolkit-restore']}
                      {!isEmpty(selectedFiles()) && selectedFiles().length > 0 ? ` (${selectedFiles().length})` : ''}
                    </button>
                    <button
                      class="ba-btn ba-red bold"
                      onClick={() => hardDeleteFiles()}
                      disabled={isEmpty(selectedFiles())}
                    >
                      {translations['toolkit-delete']}
                      {!isEmpty(selectedFiles()) && selectedFiles().length > 0 ? ` (${selectedFiles().length})` : ''}
                    </button>
                  </Show>
                </Show>
              </div>
            </div>
            {/* Popups */}
            <Show when={showUploadFilePopup()}>
              <UploadFilePopup />
            </Show>
            <Show when={showDeleteFilePopup()}>
              <DeleteFilePopup />
            </Show>
            <Show when={showUnlinkFileBrandPopup()}>
              <UnlinkFileBrandPopup />
            </Show>
            <Show when={showDeleteFolderPopup()}>
              <DeleteFolderPopup />
            </Show>
            <Show when={showNewFolderPopup()}>
              <NewFolderPopup />
            </Show>
            <Show when={showRenameFilePopup()}>
              <RenameFilePopup />
            </Show>
            <Show when={showMoveFilePopup()}>
              <MoveFilePopup />
            </Show>
            <Show when={showMoveFileToBrandPopup()}>
              <MoveMyFileToBrandPopup />
            </Show>
            <Show when={showNewBrandPopup()}>
              <NewBrandPopup />
            </Show>
            <Show when={showUpdateBrandPopup()}>
              <UpdateBrandPopup />
            </Show>
            <Show when={showDeletedBrandPopup()}>
              <DeleteBrandPopup />
            </Show>
            <Show when={showMergeBrandPopup()}>
              <MergeBrandPopup />
            </Show>
            <Show when={showMoveBrandFilePopup()}>
              <MoveBrandFilePopup />
            </Show>
            <div class={`ba-toast ${toastData().type} ${showToast() ? "show" : "hide"}`}>
              <div class='ba-toast-alert'>
                <div class='ba-left'>
                  <span class='ba-icon'></span>
                  <span class='ba-text'>{toastData().message}</span>
                </div>
                <span onClick={() => setShowToast(false)} class='ba-close-btn'></span>
              </div>
            </div>
          </div>
        </div>
      </Show>
    </>
  )
})