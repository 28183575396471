import "./components/appshell";
import "./components/banner";
import "./components/footer";
import "./components/help";
import "./components/loader";
import "./components/enom";
import "./components/open-domain-purchase-home";
import "./components/digital-file-repository";
import "./components/global-script";
import "./components/empty-image";
import "./components/templates-list";

import("./components/icon");
